import React, {ReactElement} from 'react';
import Props from './typings';
import StyledCtaButton from './styles';

const CtaButton = ({
  title,
  onClick,
  disabled,
  maxWidth,
  style = 'primary',
  className,
  children,
}: Props): ReactElement => {
  return (
    <StyledCtaButton
      className={className}
      aria-label={title ?? children}
      onClick={onClick}
      aria-disabled={disabled}
      $maxWidth={maxWidth}
      $style={style}
    >
      {children}
    </StyledCtaButton>
  );
};

export default CtaButton;
