'use client';
import React from 'react';

import Logo from '@/atoms/Logo/Logo';
import Icon from '@/atoms/Icon/Icon';
import NotificationWrapper from '@/atoms/Wrappers/Notification/NotificationWrapper';

import Search from '@/molecules/Search/Search';
import SocialIcons from '@/molecules/SocialIcons/SocialIcons';

import {getSearchAutocomplete} from '@/redux/actions/searchActions';

import configs from '@/utils/configs';
import constantsFactory from '@/utils/constants';

import colors from '@/theme/constants/colors';

import {
  StyledDesktopNavigation,
  StyledDivider,
  StyledDropdown,
  StyledListItem,
  StyledNav,
} from './styles';
import Props, {
  NavigationListProps,
  NavigationListComponentProps,
  NavigationListItemProps,
} from './typings';
import ProfileMenu from '@/molecules/Fragments/Menu/Navigation/Profile/ProfileMenu';

const {ROLES, DATA_TEST_ID} = constantsFactory();

const iconSize = 19;

const NavigationListItem = ({link}: NavigationListItemProps) => {
  const content = link.component ?? link.text;
  return (
    <>
      {(link.condition ?? true) && (
        <StyledListItem
          $bold={link.bold}
          $hasDropdown={!!link.dropdown}
          $hasWideDropdown={link.dropdown?.wide}
          onClick={link.action}
          $hasComponentAsChild={!!link.component}
          $justifyRight={!!link.justifyRight}
          $has={link.has}
        >
          {link.href ? (
            <a href={link.href} title={link.component ? link.title : undefined}>
              {content}
            </a>
          ) : (
            <span>{content}</span>
          )}
          {link.dropdown && (
            <StyledDropdown $wide={link.dropdown.wide} data-testid={DATA_TEST_ID.DROPDOWN}>
              {!!link.dropdown.wide ? (
                <div>
                  {link.dropdown.lists.map((list, key) => (
                    <NavigationList list={list} key={key} />
                  ))}
                </div>
              ) : (
                <>
                  {link.dropdown.lists.map((list, key) => (
                    <NavigationList list={list} key={key} />
                  ))}
                </>
              )}
            </StyledDropdown>
          )}
        </StyledListItem>
      )}
    </>
  );
};

const NavigationList = ({list}: NavigationListComponentProps) => {
  return (
    <figure>
      {!!list.title && <figcaption>{list.title}</figcaption>}

      <ul>
        {list.links.map((link, key) => (
          <NavigationListItem link={link} key={key} />
        ))}
        {list.highlights?.length > 0 && (
          <>
            <StyledDivider />

            {list.highlights.map((link, key) => (
              <NavigationListItem link={link} key={key} />
            ))}
          </>
        )}
      </ul>
    </figure>
  );
};

const DesktopNavigation = ({userSettings, auth, editorsPicks}: Props) => {
  const navigation = {
    logo: {
      component: <Logo width={146} height={74} color={colors.red100} />,
      href: '/',
    },
    shop: {
      text: 'Shop',
      bold: true,
      dropdown: {
        wide: true,
        lists: [
          {
            title: 'Medium',
            links: [
              {
                text: 'Painting',
                href: '/art/product_category-painting/',
              },
              {
                text: 'Printmaking',
                href: '/art/product_category-printmaking/',
              },
              {
                text: 'Photography',
                href: '/art/product_category-photography/',
              },
              {
                text: 'Sculpture',
                href: '/art/product_category-sculpture/',
              },
              {
                text: 'Drawing',
                href: '/art/product_category-drawing/',
              },
              {
                text: 'Digital art',
                href: '/art/product_category-digital-art/',
              },
              {
                text: 'Collage',
                href: '/art/product_category-collage/',
              },
            ],
            highlights: [
              {
                text: 'Discover all art',
                bold: true,
                href: '/art/',
              },
            ],
          },
          {
            title: 'Subject',
            links: [
              {
                text: 'Landscapes',
                href: '/art/subject-landscapes-sea-sky/',
              },
              {
                text: 'Abstracts',
                href: '/art/subject-abstract-conceptual/',
              },
              {
                text: 'People and portraits',
                href: '/art/subject-people-portraits/',
              },
              {
                text: 'Nudes',
                href: '/art/subject-nudes-erotic/',
              },
              {
                text: 'Florals and plants',
                href: '/art/subject-flowers-plants/',
              },
              {
                text: 'Still life',
                href: '/art/subject-still-life/',
              },
              {
                text: 'Animals',
                href: '/art/subject-animals-birds/',
              },
              {
                text: 'Architecture and cities',
                href: '/art/subject-architecture-cityscapes/',
              },
            ],
          },
          {
            title: 'For your budget',
            links: [
              {
                text: `${userSettings.currency_symbol}100 and under`,
                href: '/art/price_max-100/',
              },
              {
                text: `${userSettings.currency_symbol}500 and under`,
                href: '/art/price_max-500/',
              },
              {
                text: `${userSettings.currency_symbol}1,000 and under`,
                href: '/art/price_max-1000/',
              },
              {
                text: `${userSettings.currency_symbol}1,000 and over`,
                href: '/art/price_min-1000/',
              },
            ],
            highlights: [
              {
                component: (
                  <img
                    loading="lazy"
                    width="180"
                    height="105"
                    alt="Gift cards"
                    src="https://d2m7ibezl7l5lt.cloudfront.net/img/v2/megamenu/gift-card.svg"
                  />
                ),
                title: 'Gift cards',
                href: '/products/giftcard/',
              },
            ],
          },
          {
            title: <>Sales {<Icon type="tag" />}</>,
            links: [
              {
                text: '20% off',
                href: '/art/in_sale-20/',
              },
              {
                text: '30% off',
                href: '/art/in_sale-30/',
              },
              {
                text: '50% off',
                href: '/art/in_sale-50/',
              },
            ],
            highlights: [
              {
                text: 'All sale',
                bold: true,
                href: '/art/in_sale-on/',
              },
              {
                text: 'Free shipping',
                bold: true,
                href: '/art/free_shipping-true/',
              },
            ],
          },
        ],
      },
    },
    basket: {
      component: (
        <NotificationWrapper count={userSettings.basket_count}>
          <Icon type="trolley-empty" fontSize={iconSize} />
        </NotificationWrapper>
      ),
      href: '/basket/',
    },
    about: {
      text: 'About',
      dropdown: {
        lists: [
          {
            links: [
              {
                text: 'Our Artists',
                href: '/our-artists/',
              },
              {
                text: 'Press',
                href: '/press/',
              },
              {
                text: 'Partners',
                href: '/partners/',
              },
              {
                text: 'Personal Shopping',
                href: '/personal-shopping/',
              },
              {
                text: 'Artfinder Trade',
                href: '/trade/',
              },
              {
                text: 'Sell on Artfinder',
                href: '/sell/',
              },
            ],
          },
        ],
      },
    },
    user: {
      component: <ProfileMenu auth={auth} userSettings={userSettings} />,
      has: 'profileIcon',
    },
    dashboard: {
      component: <Icon type="settings" fontSize={iconSize} label={'Dashboard menu'} />,
      href: '/dashboard/',
      condition: auth.isAuthenticated() && auth.isAuthorised([ROLES.STAFF]),
      dropdown: {
        lists: [
          {
            links: [
              {
                text: 'Artists',
                href: '/dashboard/artists/',
              },
              {
                text: 'Galleries',
                href: '/dashboard/galleries/',
              },
              {
                text: 'Stats',
                href: '/dashboard/stats/',
              },
              {
                text: 'Orders',
                href: '/dashboard/orders/',
              },
              {
                text: 'Users',
                href: '/dashboard/user/',
              },
              {
                text: 'Applications',
                href: '/dashboard/applications/',
              },
              {
                text: 'Accounting',
                href: '/dashboard/transactions/',
              },
              {
                text: 'Art of the day',
                href: '/dashboard/aotd/',
              },
              {
                text: 'Artist of the day',
                href: '/dashboard/artist-otd/',
              },
              {
                text: 'Artist homepage promos',
                href: '/dashboard/promotions/artist-hpp/',
              },
              {
                text: 'Marketing',
                href: '/dashboard/marketing/campaigns/',
              },
              {
                text: 'Problems',
                href: '/dashboard/problems/',
              },
              {
                text: 'Flags',
                href: '/dashboard/flags/',
              },
            ],
          },
        ],
      },
    },
    manageShop: {
      component: <Icon type="dashboard" fontSize={iconSize} />,
      href: '/manage/',
      condition: auth.isAuthenticated() && auth.isAuthorised([ROLES.PROVIDER]),
    },
    inspiration: {
      text: 'Inspiration',
      bold: true,
      dropdown: {
        wide: true,
        lists: [
          {
            title: `Editors’ picks`,
            links: [],
            highlights: [
              {
                text: 'All editors’ picks',
                bold: true,
                href: '/editors-picks/',
              },
            ],
          },
          {
            title: 'Artists',
            links: [
              {
                text: 'Bestsellers',
                href: '/artist-charts/top-40-artists/',
              },
              {
                text: 'Artists of the month',
                href: '/staff-picks/artists-of-the-month/',
              },
            ],
            highlights: [
              {
                text: 'New artists',
                bold: true,
                href: '/artist-charts/top-40-brand-new-artists/',
              },
              {
                text: 'Find an artist',
                bold: true,
                href: '/artist-search/',
              },
            ],
          },
          {
            title: 'Ideas',
            links: [
              {
                text: 'Decor inspiration',
                href: '/blog/category/decor-inspiration-tips/',
              },
              {
                text: 'Art glossary',
                href: '/blog/category/art-glossary/',
              },
              {
                text: 'Trending artists',
                href: '/blog/category/trending-artists/',
              },
              {
                text: 'Art news',
                href: '/blog/category/news-and-events/',
              },
            ],
            highlights: [
              {
                component: (
                  <SocialIcons
                    size={22}
                    defaultColor={colors.darkBlue100}
                    hoverColor={colors.darkBlue50}
                  />
                ),
              },
            ],
          },
        ],
      },
    },
    search: {
      component: <Search location="header" maxWidth="450px" searchAction={getSearchAutocomplete} />,
      justifyRight: true,
    },
  };

  const buildNavigation = () => {
    const editorsPicksLinks = Array.isArray(editorsPicks)
      ? editorsPicks.map((pick) => ({
          text: pick.name,
          href: pick.url,
        }))
      : [];
    navigation.inspiration.dropdown.lists
      .find((list) => list.title === `Editors’ picks`)
      ?.links.push(...editorsPicksLinks);
    const nav: NavigationListProps = {links: []};
    configs.navigation.desktop.map((el: string) => {
      if (Object.prototype.hasOwnProperty.call(navigation, el)) nav.links.push(navigation[el]);
    });
    return nav;
  };

  return (
    <StyledDesktopNavigation data-testid={DATA_TEST_ID.DESKTOP_NAVIGATION}>
      <StyledNav>
        {/* <h2>Desktop Navigation</h2> */}

        <NavigationList list={buildNavigation()} />
      </StyledNav>
    </StyledDesktopNavigation>
  );
};

export default DesktopNavigation;
