import {StyledNotification, StyledNotificationWrapper} from './styles';
import {NotificationWrapperProps} from './typings';
import constantsFactory from '@/utils/constants';

const {DATA_TEST_ID} = constantsFactory();

const NotificationWrapper = ({
  count,
  position = 'top',
  borderColor,
  children,
}: NotificationWrapperProps) => (
  <StyledNotificationWrapper>
    {children}
    {count !== undefined && count > 0 && (
      <StyledNotification
        $position={position}
        $borderColor={borderColor}
        data-testid={DATA_TEST_ID.COUNT_NOTIFICATION}
      >
        {count < 10 ? count : '9+'}
      </StyledNotification>
    )}
  </StyledNotificationWrapper>
);

export default NotificationWrapper;
