import IconButton from '../../Buttons/Icon/IconButton';
import IconLink from '../../Links/Icon/IconLink';
import Props from './typings';

const FacebookIcon = ({
  size = 16,
  title = 'Artfinder Facebook',
  action,
  url = 'https://facebook.com/artfinderfb/',
  style,
}: Props) => {
  return action ? (
    <IconButton
      icon="facebook"
      onClick={action}
      title={title}
      style={{
        icon: {size: size, color: {default: style?.color.default, hover: style?.color.hover}},
      }}
      data-test-id="facebook-icon"
    />
  ) : (
    <IconLink
      data-test-id="facebook-icon"
      icon="facebook"
      href={url}
      title={title}
      style={{
        size: size,
        color: {default: style?.color.default, hover: style?.color.hover},
      }}
      target="_blank"
      className="facebook-icon"
      rel="nofollow"
    />
  );
};

export default FacebookIcon;
