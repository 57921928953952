'use client';
import React from 'react';
import {useSelector} from 'react-redux';
import MobileNavigation from './Mobile/MobileNavigation';
import DesktopNavigation from './Desktop/DesktopNavigation';
import {selectUserSettingsData} from '@/redux/reducers/userSettingsReducer';
import useAuth from '@/hooks/use-auth/use-auth';

const Navigation = ({editorsPicks}) => {
  const settings = useSelector(selectUserSettingsData);
  const auth = useAuth();

  return (
    <>
      <MobileNavigation userSettings={settings} auth={auth} />
      <DesktopNavigation userSettings={settings} auth={auth} editorsPicks={editorsPicks} />
    </>
  );
};

export default Navigation;
