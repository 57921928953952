import Icon from '../../../atoms/Icon/Icon';
import {StyledIconButton} from './styles';
import Props from './typings';

const IconButton = ({icon, style, title, active = false, disabled = false, onClick}: Props) => {
  return (
    <StyledIconButton
      $active={active}
      title={title}
      onClick={onClick}
      aria-disabled={disabled}
      $disabled={disabled}
      $button={style?.button}
      $icon={style?.icon}
    >
      <Icon type={icon} fontSize={style?.icon?.size} color={style?.icon?.color?.default} />
    </StyledIconButton>
  );
};

export default IconButton;
