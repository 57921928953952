import {useState, useEffect} from 'react';
import {on, off} from '../../utils/events';
import constantsFactory from '../../utils/constants';
import authServiceFactory, {authMock} from '../../services/auth-service';
const {EVENTS} = constantsFactory();

const useAuth = () => {
  const [auth, setAuth] = useState(authMock());

  useEffect(() => {
    setAuth(authServiceFactory(document, window));

    on(EVENTS.LOGIN_SUCCESS, setAuth);
    on(EVENTS.LOGOUT_SUCCESS, setAuth);
    on(EVENTS.GUEST_SESSION_CREATED, setAuth);
    on(EVENTS.REGISTRATION_SUCCESSFUL, setAuth);
    return () => {
      off(EVENTS.LOGIN_SUCCESS, setAuth);
      off(EVENTS.LOGOUT_SUCCESS, setAuth);
      off(EVENTS.GUEST_SESSION_CREATED, setAuth);
      off(EVENTS.REGISTRATION_SUCCESSFUL, setAuth);
    };
  }, []);

  return auth;
};

export default useAuth;
