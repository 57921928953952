import styled from 'styled-components';
import StyledIconLink from '../Links/Icon/styles';
import {StyledSocialIconsProps} from './typings';

export const StyledSocialIcons = styled.div<StyledSocialIconsProps>`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 15px;
  }

  ${StyledIconLink} {
    color: ${(props) => props.$defaultColor ?? 'inherit'};
    &:hover {
      color: ${(props) => props.$hoverColor ?? 'inherit'};
    }
  }
`;
