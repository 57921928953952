import styled, {css} from 'styled-components';
import StyledActionText from '@/atoms/ActionText/styles';
import colors from '@/theme/constants/colors';
import {StyledProfileMenuProps} from './typings';
import typography from '@/theme/typography';
import Link from 'next/link';
import StyledIcon from '@/atoms/Icon/styles';
import pxToRem from '@/theme/helpers/pxToRem';

export const StyledCreditLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colors.grey50};
  border-radius: 4px;
  margin: 10px;
  ${typography.Body2}
  height: 119px;
  transition: box-shadow 0.3s ease-out;

  &:hover {
    color: ${colors.darkBlue100};
    box-shadow: inset 0 2px 10px 4px rgba(0, 0, 0, 0.11);
  }

  ${StyledIcon}.icon-card {
    margin: 5px 0 7px;
  }

  ${StyledIcon}.icon-arrow-right {
    transform: rotate(90deg);
  }
`;

export const StyledCreditAmount = styled.span`
  ${typography.Body1}
  color: ${colors.red100};
`;

const loggedOut = css`
  padding: 34px 40px;

  > span:not(${StyledActionText}) {
    ${typography.Subtitle3}
    color: ${colors.darkBlue100};
    margin-bottom: 20px;
  }

  ${StyledActionText} {
    color: ${colors.red100};
    text-decoration: none;
    margin-bottom: 7px;

    &:hover {
      text-shadow: none;
      text-decoration: underline;
    }
  }
`;

const loggedIn = css`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  li > a:not(${StyledCreditLink}),
  li:last-child > span {
    display: block;
    ${typography.Body1}
    line-height: 50px;
    padding: 0 20px;
    cursor: pointer;
    color: ${colors.darkBlue100};

    &:hover {
      background: ${colors.grey25};
      font-weight: bold;
      color: ${colors.darkBlue100};
    }
  }

  li:last-child {
    box-shadow: inset 10px 0 0 0px ${colors.white}, inset -10px 0 0 0px ${colors.white},
      inset 0 1px 0 ${colors.grey25};
  }
`;

export const StyledProfileMenu = styled.div<StyledProfileMenuProps>`
  width: 250px;
  background: ${colors.white};
  box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.11);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
  top: 100%;
  right: -50px;
  ${(props) => (props.$loggedIn ? loggedIn : loggedOut)}
`;

export const StyledProfileMenuIcon = styled(Link)`
  font-size: ${pxToRem(19)};
  padding: 13px;
  color: ${colors.darkBlue100};
  display: inline-flex;

  :hover {
    color: ${colors.red100};
  }
`;

export const StyledProfileMenuContainer = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
