import styled from 'styled-components';
import pxToRem from '../../../../theme/helpers/pxToRem';
import typography from '../../../../theme/typography';
import colors from '../../../../theme/constants/colors';
import CtaButton from '@/atoms/Buttons/CTA/CtaButton';

export const StyledUserSettings = styled.div`
  padding: ${pxToRem(15)};
  width: 415px;

  h5 {
    ${typography.Title}
    margin: ${pxToRem(10)} 0;
  }

  p {
    margin: 10px 0 0;
  }
`;

export const StyledCancelButton = styled(CtaButton)`
  border-color: ${colors.grey50};
  background: ${colors.grey50};
  color: ${colors.darkBlue50};

  &:hover {
    border-color: ${colors.grey75};
    background: ${colors.grey75};
    color: ${colors.darkBlue100};
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${pxToRem(15)};

  & > ${StyledCancelButton} {
    margin-right: ${pxToRem(10)};
  }
`;

export const StyledErrorMsg = styled.div`
  display: block;
  padding: 10px 15px;
  ${typography.Body3}
  color: ${colors.red100};
  margin-top: 15px;
  background: ${colors.lightOrange};
  border-radius: 4px;
`;
