const configs: Configs = {
  apiParams: {
    0: 'category',
    1: 'product_category',
    2: 'painting',
    3: 'printmaking',
    4: 'sculpture',
    5: 'photography',
    6: 'drawing',
    7: 'digital_art',
    8: 'collage',
    9: 'q',
    10: 'subject',
    11: 'style',
    12: 'in_sale',
    13: 'free_shipping',
    14: 'framed_or_ready_to_hang',
    15: 'price_min',
    16: 'price_max',
    17: 'width_min',
    18: 'width_max',
    19: 'height_min',
    20: 'height_max',
    21: 'size_',
    22: 'has_fine_art_prints',
    23: 'shape',
    24: 'colours',
    25: 'ships_to',
    26: 'ships_from',
    27: 'product_boost',
    29: 'multiple_product_boost',
    30: 'page',
    31: 'sort',
    32: 'limit',
    33: 'user_currency',
    34: 'user_country',
    35: 'user_units',
    36: 'ranges',
  },
  urlParams: {
    0: 'product_category',
    1: 'painting',
    2: 'printmaking',
    3: 'sculpture',
    4: 'photography',
    5: 'drawing',
    6: 'digital_art',
    7: 'collage',
    8: 'q',
    9: 'subject',
    10: 'style',
    11: 'in_sale',
    12: 'free_shipping',
    13: 'framed_or_ready_to_hang',
    14: 'price_min',
    15: 'price_max',
    16: 'width_min',
    17: 'width_max',
    18: 'height_min',
    19: 'height_max',
    20: 'size_',
    21: 'has_fine_art_prints',
    22: 'shape',
    23: 'colours',
    24: 'ships_to',
    25: 'ships_from',
    26: 'product_boost',
    27: 'multiple_product_boost',
    28: 'sort',
    29: 'page',
  },
  countries: [
    {
      key: 'AF',
      label: 'Afghanistan',
    },
    {
      key: 'AL',
      label: 'Albania',
    },
    {
      key: 'DZ',
      label: 'Algeria',
    },
    {
      key: 'AS',
      label: 'American Samoa',
    },
    {
      key: 'AD',
      label: 'Andorra',
    },
    {
      key: 'AO',
      label: 'Angola',
    },
    {
      key: 'AI',
      label: 'Anguilla',
    },
    {
      key: 'AQ',
      label: 'Antarctica',
    },
    {
      key: 'AG',
      label: 'Antigua and Barbuda',
    },
    {
      key: 'AR',
      label: 'Argentina',
    },
    {
      key: 'AM',
      label: 'Armenia',
    },
    {
      key: 'AW',
      label: 'Aruba',
    },
    {
      key: 'AU',
      label: 'Australia',
    },
    {
      key: 'AT',
      label: 'Austria',
    },
    {
      key: 'AZ',
      label: 'Azerbaijan',
    },
    {
      key: 'BS',
      label: 'Bahamas',
    },
    {
      key: 'BH',
      label: 'Bahrain',
    },
    {
      key: 'BD',
      label: 'Bangladesh',
    },
    {
      key: 'BB',
      label: 'Barbados',
    },
    {
      key: 'BY',
      label: 'Belarus',
    },
    {
      key: 'BE',
      label: 'Belgium',
    },
    {
      key: 'BZ',
      label: 'Belize',
    },
    {
      key: 'BJ',
      label: 'Benin',
    },
    {
      key: 'BM',
      label: 'Bermuda',
    },
    {
      key: 'BT',
      label: 'Bhutan',
    },
    {
      key: 'BO',
      label: 'Bolivia, Plurinational State of',
    },
    {
      key: 'BQ',
      label: 'Bonaire, Sint Eustatius and Saba',
    },
    {
      key: 'BA',
      label: 'Bosnia and Herzegovina',
    },
    {
      key: 'BW',
      label: 'Botswana',
    },
    {
      key: 'BV',
      label: 'Bouvet Island',
    },
    {
      key: 'BR',
      label: 'Brazil',
    },
    {
      key: 'IO',
      label: 'British Indian Ocean Territory',
    },
    {
      key: 'BN',
      label: 'Brunei Darussalam',
    },
    {
      key: 'BG',
      label: 'Bulgaria',
    },
    {
      key: 'BF',
      label: 'Burkina Faso',
    },
    {
      key: 'BI',
      label: 'Burundi',
    },
    {
      key: 'CV',
      label: 'Cabo Verde',
    },
    {
      key: 'KH',
      label: 'Cambodia',
    },
    {
      key: 'CM',
      label: 'Cameroon',
    },
    {
      key: 'CA',
      label: 'Canada',
    },
    {
      key: 'KY',
      label: 'Cayman Islands',
    },
    {
      key: 'CF',
      label: 'Central African Republic',
    },
    {
      key: 'TD',
      label: 'Chad',
    },
    {
      key: 'CL',
      label: 'Chile',
    },
    {
      key: 'CN',
      label: 'China',
    },
    {
      key: 'CX',
      label: 'Christmas Island',
    },
    {
      key: 'CC',
      label: 'Cocos (Keeling) Islands',
    },
    {
      key: 'CO',
      label: 'Colombia',
    },
    {
      key: 'KM',
      label: 'Comoros',
    },
    {
      key: 'CG',
      label: 'Congo',
    },
    {
      key: 'CD',
      label: 'Congo, The Democratic Republic of the',
    },
    {
      key: 'CK',
      label: 'Cook Islands',
    },
    {
      key: 'CR',
      label: 'Costa Rica',
    },
    {
      key: 'HR',
      label: 'Croatia',
    },
    {
      key: 'CU',
      label: 'Cuba',
    },
    {
      key: 'CW',
      label: 'Curaçao',
    },
    {
      key: 'CY',
      label: 'Cyprus',
    },
    {
      key: 'CZ',
      label: 'Czechia',
    },
    {
      key: 'CI',
      label: "Côte d'Ivoire",
    },
    {
      key: 'DK',
      label: 'Denmark',
    },
    {
      key: 'DJ',
      label: 'Djibouti',
    },
    {
      key: 'DM',
      label: 'Dominica',
    },
    {
      key: 'DO',
      label: 'Dominican Republic',
    },
    {
      key: 'EC',
      label: 'Ecuador',
    },
    {
      key: 'EG',
      label: 'Egypt',
    },
    {
      key: 'SV',
      label: 'El Salvador',
    },
    {
      key: 'GQ',
      label: 'Equatorial Guinea',
    },
    {
      key: 'ER',
      label: 'Eritrea',
    },
    {
      key: 'EE',
      label: 'Estonia',
    },
    {
      key: 'SZ',
      label: 'Eswatini',
    },
    {
      key: 'ET',
      label: 'Ethiopia',
    },
    {
      key: 'FK',
      label: 'Falkland Islands (Malvinas)',
    },
    {
      key: 'FO',
      label: 'Faroe Islands',
    },
    {
      key: 'FJ',
      label: 'Fiji',
    },
    {
      key: 'FI',
      label: 'Finland',
    },
    {
      key: 'FR',
      label: 'France',
    },
    {
      key: 'GF',
      label: 'French Guiana',
    },
    {
      key: 'PF',
      label: 'French Polynesia',
    },
    {
      key: 'TF',
      label: 'French Southern Territories',
    },
    {
      key: 'GA',
      label: 'Gabon',
    },
    {
      key: 'GM',
      label: 'Gambia',
    },
    {
      key: 'GE',
      label: 'Georgia',
    },
    {
      key: 'DE',
      label: 'Germany',
    },
    {
      key: 'GH',
      label: 'Ghana',
    },
    {
      key: 'GI',
      label: 'Gibraltar',
    },
    {
      key: 'GR',
      label: 'Greece',
    },
    {
      key: 'GL',
      label: 'Greenland',
    },
    {
      key: 'GD',
      label: 'Grenada',
    },
    {
      key: 'GP',
      label: 'Guadeloupe',
    },
    {
      key: 'GU',
      label: 'Guam',
    },
    {
      key: 'GT',
      label: 'Guatemala',
    },
    {
      key: 'GG',
      label: 'Guernsey',
    },
    {
      key: 'GN',
      label: 'Guinea',
    },
    {
      key: 'GW',
      label: 'Guinea-Bissau',
    },
    {
      key: 'GY',
      label: 'Guyana',
    },
    {
      key: 'HT',
      label: 'Haiti',
    },
    {
      key: 'HM',
      label: 'Heard Island and McDonald Islands',
    },
    {
      key: 'VA',
      label: 'Holy See (Vatican City State)',
    },
    {
      key: 'HN',
      label: 'Honduras',
    },
    {
      key: 'HK',
      label: 'Hong Kong',
    },
    {
      key: 'HU',
      label: 'Hungary',
    },
    {
      key: 'IS',
      label: 'Iceland',
    },
    {
      key: 'IN',
      label: 'India',
    },
    {
      key: 'ID',
      label: 'Indonesia',
    },
    {
      key: 'IR',
      label: 'Iran, Islamic Republic of',
    },
    {
      key: 'IQ',
      label: 'Iraq',
    },
    {
      key: 'IE',
      label: 'Ireland',
    },
    {
      key: 'IM',
      label: 'Isle of Man',
    },
    {
      key: 'IL',
      label: 'Israel',
    },
    {
      key: 'IT',
      label: 'Italy',
    },
    {
      key: 'JM',
      label: 'Jamaica',
    },
    {
      key: 'JP',
      label: 'Japan',
    },
    {
      key: 'JE',
      label: 'Jersey',
    },
    {
      key: 'JO',
      label: 'Jordan',
    },
    {
      key: 'KZ',
      label: 'Kazakhstan',
    },
    {
      key: 'KE',
      label: 'Kenya',
    },
    {
      key: 'KI',
      label: 'Kiribati',
    },
    {
      key: 'KP',
      label: "Korea, Democratic People's Republic of",
    },
    {
      key: 'KR',
      label: 'Korea, Republic of',
    },
    {
      key: 'KW',
      label: 'Kuwait',
    },
    {
      key: 'KG',
      label: 'Kyrgyzstan',
    },
    {
      key: 'LA',
      label: "Lao People's Democratic Republic",
    },
    {
      key: 'LV',
      label: 'Latvia',
    },
    {
      key: 'LB',
      label: 'Lebanon',
    },
    {
      key: 'LS',
      label: 'Lesotho',
    },
    {
      key: 'LR',
      label: 'Liberia',
    },
    {
      key: 'LY',
      label: 'Libya',
    },
    {
      key: 'LI',
      label: 'Liechtenstein',
    },
    {
      key: 'LT',
      label: 'Lithuania',
    },
    {
      key: 'LU',
      label: 'Luxembourg',
    },
    {
      key: 'MO',
      label: 'Macao',
    },
    {
      key: 'MG',
      label: 'Madagascar',
    },
    {
      key: 'MW',
      label: 'Malawi',
    },
    {
      key: 'MY',
      label: 'Malaysia',
    },
    {
      key: 'MV',
      label: 'Maldives',
    },
    {
      key: 'ML',
      label: 'Mali',
    },
    {
      key: 'MT',
      label: 'Malta',
    },
    {
      key: 'MH',
      label: 'Marshall Islands',
    },
    {
      key: 'MQ',
      label: 'Martinique',
    },
    {
      key: 'MR',
      label: 'Mauritania',
    },
    {
      key: 'MU',
      label: 'Mauritius',
    },
    {
      key: 'YT',
      label: 'Mayotte',
    },
    {
      key: 'MX',
      label: 'Mexico',
    },
    {
      key: 'FM',
      label: 'Micronesia, Federated States of',
    },
    {
      key: 'MD',
      label: 'Moldova, Republic of',
    },
    {
      key: 'MC',
      label: 'Monaco',
    },
    {
      key: 'MN',
      label: 'Mongolia',
    },
    {
      key: 'ME',
      label: 'Montenegro',
    },
    {
      key: 'MS',
      label: 'Montserrat',
    },
    {
      key: 'MA',
      label: 'Morocco',
    },
    {
      key: 'MZ',
      label: 'Mozambique',
    },
    {
      key: 'MM',
      label: 'Myanmar',
    },
    {
      key: 'NA',
      label: 'Namibia',
    },
    {
      key: 'NR',
      label: 'Nauru',
    },
    {
      key: 'NP',
      label: 'Nepal',
    },
    {
      key: 'NL',
      label: 'Netherlands',
    },
    {
      key: 'NC',
      label: 'New Caledonia',
    },
    {
      key: 'NZ',
      label: 'New Zealand',
    },
    {
      key: 'NI',
      label: 'Nicaragua',
    },
    {
      key: 'NE',
      label: 'Niger',
    },
    {
      key: 'NG',
      label: 'Nigeria',
    },
    {
      key: 'NU',
      label: 'Niue',
    },
    {
      key: 'NF',
      label: 'Norfolk Island',
    },
    {
      key: 'MK',
      label: 'North Macedonia',
    },
    {
      key: 'MP',
      label: 'Northern Mariana Islands',
    },
    {
      key: 'NO',
      label: 'Norway',
    },
    {
      key: 'OM',
      label: 'Oman',
    },
    {
      key: 'PK',
      label: 'Pakistan',
    },
    {
      key: 'PW',
      label: 'Palau',
    },
    {
      key: 'PS',
      label: 'Palestine, State of',
    },
    {
      key: 'PA',
      label: 'Panama',
    },
    {
      key: 'PG',
      label: 'Papua New Guinea',
    },
    {
      key: 'PY',
      label: 'Paraguay',
    },
    {
      key: 'PE',
      label: 'Peru',
    },
    {
      key: 'PH',
      label: 'Philippines',
    },
    {
      key: 'PN',
      label: 'Pitcairn',
    },
    {
      key: 'PL',
      label: 'Poland',
    },
    {
      key: 'PT',
      label: 'Portugal',
    },
    {
      key: 'PR',
      label: 'Puerto Rico',
    },
    {
      key: 'QA',
      label: 'Qatar',
    },
    {
      key: 'RO',
      label: 'Romania',
    },
    {
      key: 'RU',
      label: 'Russian Federation',
    },
    {
      key: 'RW',
      label: 'Rwanda',
    },
    {
      key: 'RE',
      label: 'Réunion',
    },
    {
      key: 'BL',
      label: 'Saint Barthélemy',
    },
    {
      key: 'SH',
      label: 'Saint Helena, Ascension and Tristan da Cunha',
    },
    {
      key: 'KN',
      label: 'Saint Kitts and Nevis',
    },
    {
      key: 'LC',
      label: 'Saint Lucia',
    },
    {
      key: 'MF',
      label: 'Saint Martin (French part)',
    },
    {
      key: 'PM',
      label: 'Saint Pierre and Miquelon',
    },
    {
      key: 'VC',
      label: 'Saint Vincent and the Grenadines',
    },
    {
      key: 'WS',
      label: 'Samoa',
    },
    {
      key: 'SM',
      label: 'San Marino',
    },
    {
      key: 'ST',
      label: 'Sao Tome and Principe',
    },
    {
      key: 'SA',
      label: 'Saudi Arabia',
    },
    {
      key: 'SN',
      label: 'Senegal',
    },
    {
      key: 'RS',
      label: 'Serbia',
    },
    {
      key: 'SC',
      label: 'Seychelles',
    },
    {
      key: 'SL',
      label: 'Sierra Leone',
    },
    {
      key: 'SG',
      label: 'Singapore',
    },
    {
      key: 'SX',
      label: 'Sint Maarten (Dutch part)',
    },
    {
      key: 'SK',
      label: 'Slovakia',
    },
    {
      key: 'SI',
      label: 'Slovenia',
    },
    {
      key: 'SB',
      label: 'Solomon Islands',
    },
    {
      key: 'SO',
      label: 'Somalia',
    },
    {
      key: 'ZA',
      label: 'South Africa',
    },
    {
      key: 'GS',
      label: 'South Georgia and the South Sandwich Islands',
    },
    {
      key: 'SS',
      label: 'South Sudan',
    },
    {
      key: 'ES',
      label: 'Spain',
    },
    {
      key: 'LK',
      label: 'Sri Lanka',
    },
    {
      key: 'SD',
      label: 'Sudan',
    },
    {
      key: 'SR',
      label: 'Suriname',
    },
    {
      key: 'SJ',
      label: 'Svalbard and Jan Mayen',
    },
    {
      key: 'SE',
      label: 'Sweden',
    },
    {
      key: 'CH',
      label: 'Switzerland',
    },
    {
      key: 'SY',
      label: 'Syrian Arab Republic',
    },
    {
      key: 'TW',
      label: 'Taiwan, Province of China',
    },
    {
      key: 'TJ',
      label: 'Tajikistan',
    },
    {
      key: 'TZ',
      label: 'Tanzania, United Republic of',
    },
    {
      key: 'TH',
      label: 'Thailand',
    },
    {
      key: 'TL',
      label: 'Timor-Leste',
    },
    {
      key: 'TG',
      label: 'Togo',
    },
    {
      key: 'TK',
      label: 'Tokelau',
    },
    {
      key: 'TO',
      label: 'Tonga',
    },
    {
      key: 'TT',
      label: 'Trinidad and Tobago',
    },
    {
      key: 'TN',
      label: 'Tunisia',
    },
    {
      key: 'TR',
      label: 'Turkey',
    },
    {
      key: 'TM',
      label: 'Turkmenistan',
    },
    {
      key: 'TC',
      label: 'Turks and Caicos Islands',
    },
    {
      key: 'TV',
      label: 'Tuvalu',
    },
    {
      key: 'UG',
      label: 'Uganda',
    },
    {
      key: 'UA',
      label: 'Ukraine',
    },
    {
      key: 'AE',
      label: 'United Arab Emirates',
    },
    {
      key: 'GB',
      label: 'United Kingdom',
    },
    {
      key: 'US',
      label: 'United States',
    },
    {
      key: 'UM',
      label: 'United States Minor Outlying Islands',
    },
    {
      key: 'UY',
      label: 'Uruguay',
    },
    {
      key: 'UZ',
      label: 'Uzbekistan',
    },
    {
      key: 'VU',
      label: 'Vanuatu',
    },
    {
      key: 'VE',
      label: 'Venezuela, Bolivarian Republic of',
    },
    {
      key: 'VN',
      label: 'Viet Nam',
    },
    {
      key: 'VG',
      label: 'Virgin Islands, British',
    },
    {
      key: 'VI',
      label: 'Virgin Islands, U.S.',
    },
    {
      key: 'WF',
      label: 'Wallis and Futuna',
    },
    {
      key: 'EH',
      label: 'Western Sahara',
    },
    {
      key: 'YE',
      label: 'Yemen',
    },
    {
      key: 'ZM',
      label: 'Zambia',
    },
    {
      key: 'ZW',
      label: 'Zimbabwe',
    },
    {
      key: 'AX',
      label: 'Åland Islands',
    },
  ],
  navigation: {
    desktop: ['logo', 'shop', 'inspiration', 'search', 'manageShop', 'dashboard', 'user', 'basket'],
    mobile: {
      mainMenu: ['menu', 'search', 'logo', 'user', 'basket'],
      sideMenu: ['shop', 'inspiration', 'management', 'account'],
    },
  },
};

export default configs;

type MobileNavigationElements =
  | 'logo'
  | 'search'
  | 'user'
  | 'basket'
  | 'menu'
  | 'management'
  | 'shop'
  | 'inspiration'
  | 'account';

type DesktopNavigationElements =
  | 'logo'
  | 'shop'
  | 'inspiration'
  | 'search'
  | 'about'
  | 'user'
  | 'basket'
  | 'dashboard'
  | 'manageShop';

interface MobileNavigation {
  mainMenu: MobileNavigationElements[];
  sideMenu: MobileNavigationElements[];
}
interface Navigation {
  desktop: DesktopNavigationElements[];
  mobile: MobileNavigation;
}

interface Countries {
  key: string;
  label: string;
}

interface NumberConfig {
  [key: number]: string;
}

interface Configs {
  apiParams: NumberConfig;
  urlParams: NumberConfig;
  countries: Countries[];
  navigation: Navigation;
}
