import {css} from 'styled-components';
import {sizePx} from './helpers/size';
import colors from './constants/colors';

const none = css`
  &:focus,
  &:focus-within {
    outline: none;
  }
`;

const brand = (shadowToInsert: string | 'inset' | undefined = undefined) => {
  const inset = shadowToInsert === 'inset';

  return css`
    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: none;
      border-color: transparent;
      box-shadow: ${inset && 'inset '}${sizePx(0)} ${sizePx(0)} ${sizePx(0)} ${sizePx(1)} ${colors.red75}
        ${!inset && !!shadowToInsert && `,${shadowToInsert}`};
    }
  `;
};
export default {
  none,
  brand,
};
