'use client';
import React, {useEffect, useRef, useState} from 'react';
import Link from 'next/link';

import Logo from '@/atoms/Logo/Logo';
import Icon from '@/atoms/Icon/Icon';
import NotificationWrapper from '@/atoms/Wrappers/Notification/NotificationWrapper';

import Search from '@/molecules/Search/Search';

import configs from '@/utils/configs';
import constantsFactory from '@/utils/constants';

import colors from '@/theme/constants/colors';

import {getSearchAutocomplete} from '@/redux/actions/searchActions';

import {
  StyledCloseButton,
  StyledGoBackButton,
  StyledListItem,
  StyledMobileNavigation,
  StyledModalBackdrop,
  StyledSecondLevelMenu,
  StyledSideMenu,
} from './styles';
import Props, {NavigationListProps} from './typings';
import ProfileMenu from '@/molecules/Fragments/Menu/Navigation/Profile/ProfileMenu';

const {ROLES, DATA_TEST_ID} = constantsFactory();

const iconSize = 19;
const slideAnimationDuration = 200;

const SideMenu = ({isOpen, onClose, userSettings, auth}) => {
  const [showSecondLevel, setShowSecondLevel] = useState(false);
  const [subMenu, setSubMenu] = useState<NavigationListProps[] | null>(null);
  const linksContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!subMenu) return;

    setShowSecondLevel(true);
  }, [subMenu]);

  const closeSubMenu = () => {
    setShowSecondLevel(false);
    setTimeout(() => setSubMenu(null), slideAnimationDuration);
  };

  const closeSideMenu = () => {
    onClose();
    closeSubMenu();
    setTimeout(() => linksContainer.current?.scrollTo(0, 0), slideAnimationDuration);
  };

  const sideNavigation = {
    shop: {
      title: 'Shop',
      links: [
        {
          text: 'By medium',
          subNavigation: [
            {
              title: 'Medium',
              links: [
                {
                  text: 'Painting',
                  href: '/art/product_category-painting/',
                },
                {
                  text: 'Printmaking',
                  href: '/art/product_category-printmaking/',
                },
                {
                  text: 'Photography',
                  href: '/art/product_category-photography/',
                },
                {
                  text: 'Sculpture',
                  href: '/art/product_category-sculpture/',
                },
                {
                  text: 'Drawing',
                  href: '/art/product_category-drawing/',
                },
                {
                  text: 'Digital art',
                  href: '/art/product_category-digital-art/',
                },
                {
                  text: 'Collage',
                  href: '/art/product_category-collage/',
                },
              ],
            },
          ],
        },
        {
          text: 'By subject',
          subNavigation: [
            {
              title: 'Subject',
              links: [
                {
                  text: 'Landscapes',
                  href: '/art/subject-landscapes-sea-sky/',
                },
                {
                  text: 'Abstracts',
                  href: '/art/subject-abstract-conceptual/',
                },
                {
                  text: 'People and portraits',
                  href: '/art/subject-people-portraits/',
                },
                {
                  text: 'Nudes',
                  href: '/art/subject-nudes-erotic/',
                },
                {
                  text: 'Florals and plants',
                  href: '/art/subject-flowers-plants/',
                },
                {
                  text: 'Still life',
                  href: '/art/subject-still-life/',
                },
                {
                  text: 'Animals',
                  href: '/art/subject-animals-birds/',
                },
                {
                  text: 'Architecture and cities',
                  href: '/art/subject-architecture-cityscapes/',
                },
              ],
            },
          ],
        },
        {
          text: 'By budget',
          subNavigation: [
            {
              title: 'Budget',
              links: [
                {
                  text: `${userSettings.currency_symbol}100 and under`,
                  href: '/art/price_max-100/',
                },
                {
                  text: `${userSettings.currency_symbol}500 and under`,
                  href: '/art/price_max-500/',
                },
                {
                  text: `${userSettings.currency_symbol}1,000 and under`,
                  href: '/art/price_max-1000/',
                },
                {
                  text: `${userSettings.currency_symbol}1,000 and over`,
                  href: '/art/price_min-1000/',
                },
              ],
            },
          ],
        },
        {
          text: 'Sales',
          subNavigation: [
            {
              title: 'Sales',
              links: [
                {
                  text: '20% off',
                  href: '/art/in_sale-20/',
                },
                {
                  text: '30% off',
                  href: '/art/in_sale-30/',
                },
                {
                  text: '50% off',
                  href: '/art/in_sale-50/',
                },
                {
                  text: 'All sale',
                  bold: true,
                  href: '/art/in_sale-on/',
                },
                {
                  text: 'Free shipping',
                  bold: true,
                  href: '/art/free_shipping-true/',
                },
              ],
            },
          ],
        },
        {
          text: 'Gift cards',
          href: '/products/giftcard/',
        },
        {
          text: 'Discover all art',
          href: '/art/',
        },
      ],
    },
    management: {
      title: 'Management',
      condition: auth.isAuthenticated() && auth.isAuthorised([ROLES.STAFF, ROLES.PROVIDER]),
      links: [
        {
          text: 'Dashboard',
          condition: auth.isAuthorised([ROLES.STAFF]),
          href: '/dashboard/',
        },
        {
          text: 'Manage my shop',
          condition: auth.isAuthorised([ROLES.PROVIDER]),
          href: '/manage/',
        },
        {
          text: 'Log out',
          action: auth.logout,
        },
      ],
    },
    inspiration: {
      title: 'Inspiration',
      links: [
        {
          text: 'Artists',
          subNavigation: [
            {
              title: 'Artists',
              links: [
                {
                  text: 'Bestsellers',
                  href: '/artist-charts/top-40-artists/',
                },
                {
                  text: 'Artists of the month',
                  href: '/staff-picks/artists-of-the-month/',
                },
                {
                  text: 'New artists',
                  href: '/artist-charts/top-40-brand-new-artists/',
                },
                {
                  text: 'Find an artist',
                  bold: true,
                  href: '/artist-search/',
                },
              ],
            },
          ],
        },
        {
          text: `Editors’ picks`,
          href: '/editors-picks/',
        },
        {
          text: 'Ideas',
          href: '/blog/',
        },
      ],
    },
    account: {
      title: 'Account',
      condition:
        auth.isAuthenticated() &&
        auth.isAuthorised([ROLES.USER]) &&
        !auth.isAuthorised([ROLES.STAFF, ROLES.PROVIDER]),
      links: [
        {
          text: 'Log out',
          action: auth.logout,
        },
      ],
    },
  };

  const listItem = (li) => {
    const content = li.component ?? li.text;
    return (
      <>
        {(li.condition ?? true) && (
          <StyledListItem
            $hasSubMenu={!!li.subNavigation}
            onClick={() => {
              if (!!li.subNavigation) setSubMenu(li.subNavigation);
            }}
          >
            {li.href ? <a href={li.href}>{content}</a> : <span onClick={li.action}>{content}</span>}
          </StyledListItem>
        )}
      </>
    );
  };

  const uList = (list) => {
    return (
      <>
        {(list.condition ?? true) && (
          <ul title={list.title}>
            {list.links.map((link, key) => (
              <React.Fragment key={key}>{listItem(link)}</React.Fragment>
            ))}
          </ul>
        )}
      </>
    );
  };

  const buildNavigation = () => {
    const menuType = 'sideMenu';
    const list: NavigationListProps[] = [];
    configs.navigation.mobile[menuType].map((el: string) => {
      if (Object.prototype.hasOwnProperty.call(sideNavigation, el)) list.push(sideNavigation[el]);
    });
    return list;
  };

  return (
    <>
      <StyledModalBackdrop $show={isOpen} onClick={closeSideMenu}></StyledModalBackdrop>

      <StyledSideMenu $isOpen={isOpen} data-testid={DATA_TEST_ID.SIDE_NAVIGATION}>
        <div ref={linksContainer}>
          <StyledCloseButton onClick={closeSideMenu} aria-label="Close navigation">
            &times;
          </StyledCloseButton>

          {buildNavigation().map((ul, key) => (
            <React.Fragment key={key}>{uList(ul)}</React.Fragment>
          ))}
        </div>

        <StyledSecondLevelMenu
          $isOpen={showSecondLevel}
          $animationDuration={slideAnimationDuration}
          data-testid={DATA_TEST_ID.SIDE_NAVIGATION_SECOND_LEVEL}
        >
          <StyledGoBackButton onClick={closeSubMenu} aria-label="Go back"></StyledGoBackButton>

          <StyledCloseButton onClick={closeSideMenu} aria-label="Close navigation">
            &times;
          </StyledCloseButton>

          {!!subMenu &&
            Array.isArray(subMenu) &&
            subMenu.map((ul, key) => <React.Fragment key={key}>{uList(ul)}</React.Fragment>)}
        </StyledSecondLevelMenu>
      </StyledSideMenu>
    </>
  );
};

const MobileNavigation = ({userSettings, auth}: Props) => {
  const [showSideMenu, setShowSideMenu] = useState(false);

  const openSideMenu = () => {
    setShowSideMenu(true);
  };

  const closeSideMenu = () => {
    setShowSideMenu(false);
  };

  const mainNavigation = {
    logo: {
      component: <Logo width={120} height={22} color={colors.red100} />,
      has: 'logo',
      href: '/',
    },
    basket: {
      component: (
        <NotificationWrapper count={userSettings.basket_count}>
          <Icon type="trolley-empty" fontSize={iconSize} label="Checkout" />
        </NotificationWrapper>
      ),
      href: '/basket/',
    },
    about: {
      text: 'About',
      dropdown: {
        lists: [],
      },
    },
    user: {
      component: <ProfileMenu auth={auth} userSettings={userSettings} allowDropdown={false} />,
    },
    menu: {
      component: <Icon type="menu" fontSize={iconSize} label="Menu" />,
      action: openSideMenu,
      has: 'menuIcon',
    },
    search: {
      component: (
        <Search
          location="header"
          collapsible
          searchAction={getSearchAutocomplete}
          maxWidth="calc(100vw - 80px)"
        />
      ),
      has: 'search',
    },
    manageShop: {
      component: <Icon type="dashboard" fontSize={iconSize} label="Manage shop" />,
      link: '/manage/',
      condition: auth.isAuthenticated() && auth.isAuthorised([ROLES.PROVIDER]),
    },
  };

  const listItem = (link) => {
    const content = link.component ?? link.text;

    return (
      <>
        {(link.condition ?? true) && (
          <StyledListItem onClick={link.action} $has={link.has}>
            {link.href ? (
              <Link href={link.href} title={link.title ?? undefined}>
                {content}
              </Link>
            ) : (
              <span>{content}</span>
            )}
          </StyledListItem>
        )}
      </>
    );
  };

  const uList = (list) => {
    return (
      <ul>
        {list.links.map((link, key) => (
          <React.Fragment key={key}>{listItem(link)}</React.Fragment>
        ))}
      </ul>
    );
  };

  const buildNavigation = () => {
    const menuType = 'mainMenu';
    const list: NavigationListProps = {links: []};
    configs.navigation.mobile[menuType].map((el: string) => {
      if (Object.prototype.hasOwnProperty.call(mainNavigation, el))
        list.links.push(mainNavigation[el]);
    });
    return list;
  };

  return (
    <>
      <StyledMobileNavigation data-testid={DATA_TEST_ID.MOBILE_NAVIGATION}>
        {/* <h2>Mobile Navigation</h2> */}

        {uList(buildNavigation())}
      </StyledMobileNavigation>

      <SideMenu
        isOpen={showSideMenu}
        onClose={closeSideMenu}
        userSettings={userSettings}
        auth={auth}
      />
    </>
  );
};

export default MobileNavigation;
