import styled from 'styled-components';
import {StyledSVGContainerProps} from './typings';

export const StyledLogoContainer = styled.div<StyledSVGContainerProps>`
  width: ${(props) => (props.$width ? `${props.$width}px` : 'auto')};
  height: ${(props) => (props.$height ? `${props.$height}px` : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: auto;
    height: auto;
    flex-grow: 1;
    ${(props) => props.$color && `color: ${props.$color};`}
  }
`;
