import Props from './typings';
import StyledIconLink from './styles';
import Icon from '../../../atoms/Icon/Icon';
import {ReactElement} from 'react';

const IconLink = ({
  icon,
  title,
  href,
  style,
  target,
  disabled = false,
  className,
  rel,
}: Props): ReactElement => {
  return (
    <StyledIconLink
      $size={style?.size}
      $color={style?.color}
      title={title}
      href={href}
      target={target}
      $disabled={disabled}
      className={className}
      rel={rel}
    >
      <Icon type={icon} fontSize={style?.size} color={style?.color?.default} />
    </StyledIconLink>
  );
};

export default IconLink;
