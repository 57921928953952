'use client';
import styled, {css} from 'styled-components';
import colors from '../../theme/constants/colors';
import Typography from '../../theme/typography';
import {StyledProps} from './typings';
import pxToRem from '../../theme/helpers/pxToRem';

const font = (size) => {
  switch (size) {
    case 12:
      return Typography.Body3;
    case 14:
      return Typography.Body2;
    case 16:
      return Typography.Body1;
    default:
      return Typography.Body1;
  }
};

const StyledActionText = styled.span<StyledProps>`
  ${(props) => font(props.$fontSize)}
  text-decoration: underline;
  cursor: pointer;
  letter-spacing: ${pxToRem(1)};

  &:hover {
    text-shadow: 0 0 ${pxToRem(0.4)} ${colors.darkBlue100},
      0 0 ${pxToRem(0.4)} ${colors.darkBlue100}, 0 0 ${pxToRem(0.4)} ${colors.darkBlue100},
      0 0 ${pxToRem(0.4)} ${colors.darkBlue100}, 0 0 ${pxToRem(0.4)} ${colors.darkBlue100};
  }

  ${(props) =>
    props.$disabled &&
    css`
      pointer-events: none;
      color: ${colors.grey100};
    `}
`;

export default StyledActionText;
