'use client';
import styled from 'styled-components';
import colors from '../../theme/constants/colors';
import breakpoints from '../../theme/constants/breakpoints';
import {StyledContainer} from '../../../../app/styles';
import typography from '../../theme/typography';
import pxToRem from '@/theme/helpers/pxToRem';
import {sizePx} from '@/theme/helpers/size';
import StyledActionButton from '../../molecules/Buttons/Action/styles';
import {StyledColumnProps} from './typings';
import {StyledSocialIcons} from '../../molecules/SocialIcons/styles';
import Image from 'next/image';

const invisibleHeader = `
  width: 0;
  height: 0;
  color: ${colors.darkBlue100};
  font-size: 1px;
  margin: 0 !important;
  line-height: 0;
`;

export const StyledDescription = styled.dl`
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: ${pxToRem(10)};
  margin: 0 0 ${pxToRem(15)};
  color: ${colors.grey100};
  ${typography.Body2}

  dt {
    grid-column-start: 1;
  }

  dd {
    grid-column-start: 2;
    margin: 0;
  }
`;

export const StyledFooter = styled.footer`
  background: ${colors.darkBlue100};
  flex-grow: 0;
  flex-shrink: 0;
  color: ${colors.grey100};

  h5 {
    color: ${colors.white};
    ${typography.Subtitle1};
    margin-bottom: 0;
  }

  small {
    display: block;
    ${typography.Body3}
    color: ${colors.grey100};
    margin-bottom: ${pxToRem(30)};

    > * {
      border-left: 1px solid ${colors.grey100};
      margin-left: ${pxToRem(15)};
      padding-left: ${pxToRem(15)};
      text-decoration: none;
      color: ${colors.grey100};

      &:hover {
        color: ${colors.white};
      }
    }
  }

  ${StyledActionButton} {
    border-radius: 0;
    border-color: ${colors.grey100};
    color: ${colors.grey100};

    &:hover {
      color: ${colors.white};
    }
  }

  ${StyledContainer} {
    > h2 {
      ${invisibleHeader}
    }

    &.mobile-layout {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: ${pxToRem(20)};

      @media only screen and ${breakpoints.device.m} {
        display: none;
      }

      > a {
        color: ${colors.grey100};
        text-decoration: none;
        ${typography.Body2}
        line-height: ${pxToRem(30)};

        &:first-of-type {
          margin-top: ${pxToRem(15)};
        }

        &:hover {
          color: ${colors.white};
        }
      }

      .footer-b-corporation {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      aside:first-of-type {
        display: flex;
        flex-direction: column;
        align-items: center;

        h5 {
          margin-bottom: ${pxToRem(10)};
          margin-top: ${pxToRem(15)};
        }
      }

      ${StyledDescription} {
        grid-template-columns: 1fr 1fr;

        dt {
          text-align: right;
        }
      }
    }

    &.desktop-layout {
      display: none;

      @media only screen and ${breakpoints.device.m} {
        display: block;
      }
    }
  }

  @media only screen and ${breakpoints.device.m} {
    ${StyledSocialIcons} {
      margin-right: ${pxToRem(15)};
    }
  }
`;

export const StyledColumn = styled.div<StyledColumnProps>`
  padding: ${sizePx(7)};
  ${(props) =>
    props.$optional &&
    `
  display: none;

  @media only screen and ${breakpoints.device.l} {
    display: block;
  }
  `}
`;

export const StyledColumnContainer = styled.div`
  display: flex;
  flex-direction: row;

  > ${StyledColumn}:first-child {
    padding-left: 0;
  }

  > *:last-child {
    margin-left: auto;
  }
`;

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li > a,
  li > span {
    color: ${colors.grey100};
    text-decoration: none;
    ${typography.Body2}

    &:hover {
      color: ${colors.white};
    }
  }
`;

export const StyledAsideCorporations = styled.aside`
  > *:not(:last-child) {
    margin: ${pxToRem(15)} 0;
  }

  > h5 {
    ${invisibleHeader}
  }
`;

export const StyledCommonSearches = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin: 0;
  padding: ${pxToRem(15)} 0;
  border-top: 1px solid ${colors.grey100};

  li > a {
    color: ${colors.grey100};
    text-decoration: none;
    ${typography.Body2}

    &:hover {
      color: ${colors.white};
    }
  }
`;

export const StyledTrustPilotImg = styled(Image)`
  width: 100%;
  max-width: 300px;
`;
