import Footer from '@/organisms/Footer/Footer';
import Navigation from '@/organisms/Navigation/Navigation';

const PageLayout = ({editorsPicks, children}) => {
  return (
    <>
      <Navigation editorsPicks={editorsPicks} />

      {children}

      <Footer />
    </>
  );
};

export default PageLayout;
