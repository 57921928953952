import styled, {css} from 'styled-components';
import {StyledProps} from './typings';

const StyledImgFigure = styled.figure<StyledProps>`
  ${(props) => css`
    background-color: #${props.$mainColor};
    position: relative;
    margin: 0;
    overflow: hidden;
    ${props.$responsive
      ? css`
          aspect-ratio: ${props.$width} / ${props.$height};
        `
      : css`
          width: ${props.$width}px;
          height: ${props.$height}px;
        `}

    & img {
      animation: fadeIn 1.5s;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `}

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default StyledImgFigure;
