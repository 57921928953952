import IconButton from '../../Buttons/Icon/IconButton';
import IconLink from '../../Links/Icon/IconLink';
import Props from './typings';

const HouzzIcon = ({
  size = 16,
  title = 'Artfinder Houzz',
  action,
  url = 'https://www.houzz.co.uk/pro/artfindercom/artfinder',
  style,
}: Props) => {
  return action ? (
    <IconButton
      icon="houzz"
      onClick={action}
      title={title}
      style={{
        icon: {size: size, color: {default: style?.color.default, hover: style?.color.hover}},
      }}
      data-test-id="houzz-icon"
    />
  ) : (
    <IconLink
      data-test-id="houzz-icon"
      icon="houzz"
      href={url}
      title={title}
      style={{
        size: size,
        color: {default: style?.color.default, hover: style?.color.hover},
      }}
      target="_blank"
      className="houzz-icon"
      rel="nofollow"
    />
  );
};

export default HouzzIcon;
