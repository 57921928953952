import styled, {css} from 'styled-components';
import StyledIcon from '../../../atoms/Icon/styles';
import {StyledIconLinkProps} from './typings';
import colors from '@/theme/constants/colors';

const StyledIconLink = styled.a<StyledIconLinkProps>`
  cursor: pointer;
  border: none;
  text-decoration: none;
  color: inherit;

  &:hover {
    ${StyledIcon} {
      ${(props) =>
        props.$color?.hover &&
        css`
          color: ${props.$color.hover};
        `}
    }
  }

  // disabled state
  ${(props) =>
    props.$disabled &&
    css`
      pointer-events: none;

      ${StyledIcon} {
        color: ${colors.grey100};
      }
    `}
`;

export default StyledIconLink;
