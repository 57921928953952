import hmacSHA1 from 'crypto-js/hmac-sha1';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

/**
 * Generates thumbor compatible URL
 */
export const ThumborUrlBuilder = (serverUrl, signingKey) => {
  const TOP = 'top';
  const MIDDLE = 'middle';
  const BOTTOM = 'bottom';
  const RIGHT = 'right';
  const CENTER = 'center';
  const LEFT = 'left';

  let imagePath = '';
  let width = 0;
  let height = 0;
  let smart = false;
  let fitInFlag = false;
  let withFlipHorizontally = false;
  let withFlipVertically = false;
  let halignValue = null;
  let valignValue = null;
  let cropValues = null;
  let meta = false;
  const filtersCalls = [];

  return {
    setImagePath(newImagePath) {
      imagePath =
        newImagePath.charAt(0) === '/' ? newImagePath.substring(1, imagePath.length) : newImagePath;
      return this;
    },

    getOperationPath() {
      const parts = this.urlParts();
      if (0 === parts.length) {
        return '';
      } else {
        return parts.join('/') + '/';
      }
    },

    urlParts() {
      if (!imagePath) {
        throw new Error("The image url can't be null or empty.");
      }

      const parts = [];
      if (meta) {
        parts.push('meta');
      }

      if (cropValues) {
        parts.push(
          cropValues.left + 'x' + cropValues.top + ':' + cropValues.right + 'x' + cropValues.bottom,
        );
      }

      if (fitInFlag) {
        parts.push('fit-in');
      }

      if (width || height || withFlipHorizontally || withFlipVertically) {
        let sizeString = '';
        if (withFlipHorizontally) {
          sizeString += '-';
        }

        sizeString += width;
        sizeString += 'x';
        if (withFlipVertically) {
          sizeString += '-';
        }

        sizeString += height;
        parts.push(sizeString);
      }

      if (halignValue) {
        parts.push(halignValue);
      }

      if (valignValue) {
        parts.push(valignValue);
      }

      if (smart) {
        parts.push('smart');
      }

      if (filtersCalls) {
        for (let i = 0; i < filtersCalls.length; i++) {
          parts.push('filters:' + filtersCalls[i]);
        }
      }

      return parts;
    },

    resize(newWidth, newHeight) {
      width = newWidth;
      height = newHeight;
      fitInFlag = false;
      return this;
    },

    smartCrop(halign = CENTER, valign = MIDDLE) {
      if (halign === LEFT || halign === RIGHT || halign === CENTER) {
        halignValue = halign;
      } else {
        throw new Error('Horizontal align must be left, right or center.');
      }

      if (valign === TOP || valign === BOTTOM || valign === MIDDLE) {
        valignValue = valign;
      } else {
        throw new Error('Vertical align must be top, bottom or middle.');
      }

      smart = true;
      return this;
    },

    fitIn(newWidth, newHeight) {
      width = newWidth;
      height = newHeight;
      fitInFlag = true;
      return this;
    },

    flipHorizontally() {
      withFlipHorizontally = true;
      return this;
    },

    flipVertically() {
      withFlipVertically = true;
      return this;
    },

    /**
     * Specify horizontal alignment used if width is altered due to cropping
     */
    halign(newHalign = CENTER) {
      if (newHalign === LEFT || newHalign === RIGHT || newHalign === CENTER) {
        halignValue = newHalign;
      } else {
        throw new Error('Horizontal align must be left, right or center.');
      }
      return this;
    },

    /**
     * Specify vertical alignment used if height is altered due to cropping
     */
    valign(newValign = MIDDLE) {
      if (newValign === TOP || newValign === BOTTOM || newValign === MIDDLE) {
        valignValue = newValign;
      } else {
        throw new Error('Vertical align must be top, bottom or middle.');
      }
      return this;
    },

    metaDataOnly(metaDataOnlyValue = true) {
      meta = metaDataOnlyValue;
      return this;
    },

    /**
     * Append a filter, e.g. quality(80)
     */
    filter(filterCall) {
      filtersCalls.push(filterCall);
      return this;
    },

    crop(leftVal, topVal, rightVal, bottomVal) {
      cropValues = {
        left: leftVal,
        top: topVal,
        right: rightVal,
        bottom: bottomVal,
      };
      return this;
    },

    /**
     * Combine image url and operations with secure and unsecure (unsafe) paths
     */
    buildUrl() {
      const operation = this.getOperationPath();
      if (signingKey) {
        const hmacDigest = Base64.stringify(hmacSHA1(operation + imagePath, signingKey));

        const key = hmacDigest.replace(/\+/g, '-').replace(/\//g, '_');
        return serverUrl + '/' + key + '/' + operation + imagePath;
      } else {
        return serverUrl + '/unsafe/' + operation + imagePath;
      }
    },
  };
};

export const nextImageThumborLoader = ({src, width, height = 0, quality = 100}) => {
  const THUMBOR_URL = process.env.IMAGE_SERVER_URL;
  const words = Base64.parse(process.env.IMAGE_SERVER_B_S);
  const THUMBOR_K = Utf8.stringify(words);

  const builder = ThumborUrlBuilder(THUMBOR_URL, THUMBOR_K);

  if (builder && src) {
    builder.setImagePath(src).resize(width, height);
    if (quality && quality !== 100) {
      builder.filter(`quality(${quality})`);
    }

    return builder.buildUrl();
  }

  return process.env.MISSING_IMAGE_PATH;
};
