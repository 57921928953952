import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Icon from '@/atoms/Icon/Icon';

import colors from '@/theme/constants/colors';
import constantsFactory from '@/utils/constants';

import {
  StyledCancelButton,
  StyledDropdownLink,
  StyledSearch,
  StyledSearchButton,
  StyledSearchDropdown,
  StyledDropdownA,
} from './styles';
import Props from './typings';
import useOutsideClick from '@/hooks/use-outside-click/use-outside-click';
import {resetKeyword, setKeyword} from '@/redux/actions/filterSettingsActions';
import useMounted from '@/hooks/use-mounted/use-mounted';
import {selectSearchSettings} from '@/redux/reducers/filterSettingsReducer';
import paramsHelper from '@/utils/paramsHelper';
import configs from '@/utils/configs';
import {prefix} from 'src/services/plp-url-params';
import {useRouter} from 'next/router';
import {searchEvent} from '@/utils/gtm';
import Image from '@/atoms/Image/Image';

const minSearchLength = 3;
const {DATA_TEST_ID} = constantsFactory();

const Search = ({
  location = 'content',
  topLinkTarget = 'plp',
  placeholder,
  maxWidth,
  collapsible = false,
  searchAction,
}: Props) => {
  const dispatch = useDispatch();
  const hasMounted = useMounted();
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownMaxHeight = useRef(undefined);
  const appliedFilters = useSelector(selectSearchSettings);
  const [input, setInput] = useState('');
  const [topLink, setTopLink] = useState('/');
  const [collapsed, setCollapsed] = useState(collapsible);
  const [searchResult, setSearchResult] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const allLinksRefs = useRef<{[key: number]: HTMLAnchorElement | null}>({});
  const lastCursorPos = useRef({
    x: 0,
    y: 0,
  });

  const search = (keyWord) => {
    const successCallbacks = [
      (data) => {
        setSearchResult(data);
      },
    ];
    dispatch(searchAction(keyWord, successCallbacks));
  };

  const searchInput = (e) => {
    e.preventDefault();
    setInput(e.target.value);
    setSelectedOption(0);
    if (!!e.target.value.length && !showDropdown) {
      setShowDropdown(true);
    } else if (!e.target.value.length) {
      setSearchResult(null);
      setShowDropdown(false);
    }

    if (e.target.value.length >= minSearchLength) search(e.target.value);
  };

  const clearInput = () => {
    if (!inputRef.current) return;

    setInput('');
    setShowDropdown(false);
    setSearchResult(null);
  };

  const expandSearch = () => {
    if (!inputRef.current) return;

    if (collapsed) {
      setCollapsed(false);
      inputRef.current.focus({preventScroll: true});
    }
  };

  const searchIconClick = () => {
    if (!inputRef.current) return;

    if (!!input.length) {
      allLinksRefs.current[selectedOption]?.click();
    } else {
      if (collapsible) {
        expandSearch();
      } else {
        inputRef.current.focus();
      }
    }
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const focusOut = () => {
    if (collapsible && !collapsed && !input.length) setCollapsed(true);
  };

  const focusIn = () => {
    if (!showDropdown && !!input.length) {
      setShowDropdown(true);
    }

    if (collapsible && collapsed) {
      expandSearch();
    }
  };

  const keyPress = (e) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        if (!input.length && !showDropdown) {
          dispatch(resetKeyword());
        } else {
          allLinksRefs.current[selectedOption]?.click();
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (!input.length || !showDropdown) return;

        if (
          allLinksRefs.current.hasOwnProperty(selectedOption - 1) &&
          allLinksRefs.current[selectedOption - 1]
        ) {
          setSelectedOption(selectedOption - 1);
        } else {
          setSelectedOption(parseInt(findLastKey(allLinksRefs.current)));
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        if (!input.length || !showDropdown) return;

        if (
          allLinksRefs.current.hasOwnProperty(selectedOption + 1) &&
          allLinksRefs.current[selectedOption + 1]
        ) {
          setSelectedOption(selectedOption + 1);
        } else {
          setSelectedOption(0);
        }
        break;
      default:
        break;
    }
  };

  const linkHover = (e, key: number) => {
    if (e.screenX !== lastCursorPos.current.x || e.screenY !== lastCursorPos.current.y) {
      lastCursorPos.current.x = e.screenX;
      lastCursorPos.current.y = e.screenY;
      if (selectedOption !== key) setSelectedOption(key);
    }
  };

  const dropdownMouseLeave = () => {
    setSelectedOption(0);
  };

  const findLastKey = (object) => {
    const keys = Object.keys(object);

    for (let i = keys.length - 1; i >= 0; i--) {
      const key = keys[i];
      if (object[key] !== null) {
        return key;
      }
    }
    return '0';
  };

  const calcMaxHeight = useCallback((node) => {
    if (!node) return undefined;

    dropdownMaxHeight.current = node.getBoundingClientRect().top;
  }, []);

  const searchRef = useOutsideClick(closeDropdown, showDropdown);

  const list = (searchResultObject) => {
    let keyCounter = 1;
    return (
      <>
        <figure>
          <figcaption>Search</figcaption>

          <ul>
            <li key={0}>
              <StyledDropdownLink
                href={topLink}
                ref={(ref) => (allLinksRefs.current[0] = ref)}
                $hover={0 === selectedOption}
                onMouseMove={(e) => {
                  linkHover(e, 0);
                }}
                onClick={(e) => {
                  if (location === 'header' && router.pathname.includes('/art/')) {
                    e.preventDefault();
                    dispatch(setKeyword(input));
                  }
                  searchEvent(input);
                  inputRef.current?.blur();
                  closeDropdown();
                }}
              >
                <Icon type="search" fontSize={18} color={colors.darkBlue25} />

                {`${input} in All ${topLinkTarget === 'plp' ? 'Art' : 'Artists'}`}
              </StyledDropdownLink>
            </li>
          </ul>
        </figure>

        {!!searchResultObject &&
          Object.keys(searchResultObject)
            .filter((listName) => searchResultObject[listName].length > 0)
            .map((listName) => (
              <figure key={listName}>
                <figcaption>{listName}</figcaption>

                <ul>
                  {searchResultObject[listName].map((listItem) => {
                    const key = keyCounter++;
                    return (
                      <li key={key}>
                        <StyledDropdownA
                          href={listItem.url}
                          ref={(ref) => (allLinksRefs.current[key] = ref)}
                          $hover={key === selectedOption}
                          onMouseMove={(e) => {
                            linkHover(e, key);
                          }}
                          onClick={() => {
                            searchEvent(listItem.url);
                            inputRef.current?.blur();
                            closeDropdown();
                          }}
                        >
                          {!!listItem.images?.length ? (
                            <Image
                              path={listItem.images[0].path}
                              width={34}
                              height={34}
                              alt={listItem.name}
                              retina
                            />
                          ) : (
                            <Icon type="search" fontSize={18} color={colors.darkBlue25} />
                          )}

                          <p>{listItem.name}</p>
                        </StyledDropdownA>
                      </li>
                    );
                  })}
                </ul>
              </figure>
            ))}
      </>
    );
  };

  useEffect(() => {
    if (!showDropdown) return;

    if (allLinksRefs.current.hasOwnProperty(selectedOption) && allLinksRefs.current[selectedOption])
      allLinksRefs.current[selectedOption]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
  }, [selectedOption]);

  useEffect(() => {
    if (!hasMounted) return;

    // To add focus on search clear only after input reset
    if (inputRef.current && !input.length && document.activeElement !== inputRef.current)
      inputRef.current.focus({preventScroll: true});
  }, [input]);

  useEffect(() => {
    if (!hasMounted) return;

    let url = `/`;

    if (topLinkTarget === 'plp') {
      url = `/art/q-${input}/`;

      if (router.pathname.includes('/art/')) {
        url =
          '/art/' +
          prefix(
            paramsHelper(appliedFilters).build({q: input}).sort(configs.urlParams).result,
          ).join('/');
      }
    } else if (topLinkTarget === 'artist-search') {
      url = `/artist-search/sort-best_match/paginate-60/name-${input}/`;
    }

    setTopLink(url);
  }, [appliedFilters, input]);

  return (
    <StyledSearch
      ref={searchRef}
      role="search"
      $maxWidth={maxWidth}
      $location={location}
      $collapsed={collapsed}
      $collapsible={collapsible}
    >
      <input
        ref={inputRef}
        type="search"
        aria-label="Search input"
        placeholder={placeholder ?? 'Search...'}
        autoComplete={'off'}
        maxLength={200}
        value={input}
        onChange={searchInput}
        onFocus={focusIn}
        onBlur={focusOut}
        onKeyDown={keyPress}
      />

      <StyledSearchButton aria-label="Submit search" $location={location} onClick={searchIconClick}>
        <Icon type="search" />
      </StyledSearchButton>

      {input.length > 0 && (
        <StyledCancelButton aria-label="Cancel search" $location={location} onClick={clearInput}>
          <Icon type="close" />
        </StyledCancelButton>
      )}

      {showDropdown && (
        <StyledSearchDropdown
          ref={calcMaxHeight}
          $maxHeight={dropdownMaxHeight.current}
          data-testid={DATA_TEST_ID.DROPDOWN}
          onMouseLeave={() => {
            dropdownMouseLeave();
          }}
        >
          {list(searchResult)}
        </StyledSearchDropdown>
      )}
    </StyledSearch>
  );
};

export default Search;
