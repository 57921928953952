import React, {ReactElement} from 'react';
import Props, {LoaderType} from './typings';
import StyledImgFigure from './styles';
import ImageLoader from '../../../services/image-loader';

const Image = ({
  path,
  alt,
  mainColor = 'ebe8e5',
  width = 0,
  srcSetDef,
  sizes,
  height = 0,
  quality = 100,
  responsive,
  loading = 'lazy',
  fallbackWidth,
  retina = false,
}: Props): ReactElement => {
  const isLoader = (loader: string): loader is LoaderType => {
    switch (loader) {
      case 'thumbor':
      case 'default':
        return true;
      default:
        console.error('Wrong loader is passed to image!');
        return false;
    }
  };

  const getLoader = (path, width, height, quality): string => {
    if (!path) return '';

    let loader;
    if (process.env.NEXT_IMAGE_LOADER && isLoader(process.env.NEXT_IMAGE_LOADER)) {
      loader = process.env.NEXT_IMAGE_LOADER;
    } else {
      loader = 'default';
    }
    // eslint-disable-next-line new-cap
    return ImageLoader(path, width, quality, height).get(loader);
  };

  const sizeMultiplier = retina ? 2 : 1;
  const srcImgWidth = fallbackWidth ?? width * sizeMultiplier;
  const srcImgHeight = height * sizeMultiplier;
  return (
    <StyledImgFigure
      $mainColor={mainColor}
      $width={width}
      $height={height}
      $responsive={responsive}
    >
      <img
        loading={loading}
        src={getLoader(path, srcImgWidth, srcImgHeight, quality)}
        srcSet={srcSetDef}
        sizes={sizes}
        alt={alt}
      />
    </StyledImgFigure>
  );
};

export default Image;
