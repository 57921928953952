import styled, {css} from 'styled-components';
import colors from '../../../theme/constants/colors';
import zIndex from '../../../theme/constants/zIndex';
import breakpoints from '../../../theme/constants/breakpoints';
import {
  StyledListItemProps,
  StyledModalBackdropProps,
  StyledSecondLevelMenuProps,
  StyledSideMenuProps,
} from './typings';
import pxToRem from '@/theme/helpers/pxToRem';
import {sizePx} from '@/theme/helpers/size';
import container from '../../../theme/constants/container';
import typography from '@/theme/typography';
import {StyledSearch} from '@/molecules/Search/styles';
import {StyledLogoContainer} from '@/atoms/Logo/styles';
import scrollbar from '@/theme/elements/scrollbar';
import {StyledProfileMenuContainer} from '@/molecules/Fragments/Menu/Navigation/Profile/styles';
import focus from '@/theme/focus';

const navigationHeight = 74;

const liHas = (item: string) => {
  switch (item) {
    case 'logo':
      return css`
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        margin: auto !important;
        width: fit-content;
      `;
    case 'search':
      return css`
        position: absolute;
        left: 40px;
        bottom: 0;
        top: 0;
        margin: 0 !important;
      `;
    case 'menuIcon':
      return css`
        margin-right: auto !important;
      `;
    default:
      break;
  }
};

export const StyledListItem = styled.li<StyledListItemProps>`
  ${(props) =>
    props.$hasSubMenu
      ? css`
          ${typography.Subtitle1}

          &::after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-top: 3px solid;
            border-right: 3px solid;
            border-color: inherit;
            transform: rotate(45deg);
          }
        `
      : css`
          ${typography.Body1}
        `}

  ${(props) => props.$has && liHas(props.$has)}      

  ${StyledLogoContainer} {
    @media only screen and ${breakpoints.device.m} {
      width: 146px;
      height: 26px;
    }
  }
`;

export const StyledMobileNavigation = styled.nav`
  height: ${navigationHeight}px;
  background: ${colors.grey25};
  width: 100%;
  padding: ${container.padding};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: ${zIndex.fixed};

  @media only screen and ${breakpoints.device.s} {
    position: sticky;
    top: 0;
  }

  @media only screen and ${breakpoints.device.l} {
    display: none;
  }

  ${StyledSearch} {
    z-index: 1;
  }

  > ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;

    > ${StyledListItem} {
      padding: 0;
      margin: 0 ${sizePx(5)};
      line-height: ${navigationHeight}px;
      display: flex;
      align-items: center;
      height: ${navigationHeight}px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      > span,
      > a {
        cursor: pointer;
        text-decoration: none;
        color: ${colors.darkBlue100};
        display: flex;
        align-items: center;
        height: 100%;

        // TODO: dashboard icon has bigger width and aligned to the top, so need this to align all icons evenly.
        .icon-dashboard {
          margin-top: 3px;
          margin-right: 1px;
        }

        &:hover {
          color: ${colors.red100};
        }

        > ${StyledProfileMenuContainer} {
          height: 100%;
        }

        > .bi-logo {
          color: ${colors.red100};
        }
      }
    }
  }
`;

export const StyledSecondLevelMenu = styled.div<StyledSecondLevelMenuProps>`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  ${(props) =>
    css`
      transition: all ${props.$animationDuration}ms ease-in;
    `}
  width: 300px;
  height: 100vh;
  right: -300px;
  position: absolute;
  ${(props) => props.$isOpen && 'transform: translate3d(-300px, 0, 0);'}
`;

export const StyledSideMenu = styled.div<StyledSideMenuProps>`
  display: flex;
  flex-direction: column;
  background: ${colors.white};
  width: 300px;
  position: fixed;
  z-index: ${zIndex.modal}; // Chose this zIndex as it will have a backdrop and needs to be on top of navigation that may also be fixed
  transition: all 200ms ease-in;
  top: 0;
  bottom: 0;
  left: -300px;
  overflow: hidden;
  ${(props) => props.$isOpen && 'transform: translate3d(300px, 0, 0);'}

  @media only screen and ${breakpoints.device.l} {
    display: none;
  }

  > div:not(${StyledSecondLevelMenu}) {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    ${scrollbar.primary}
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: ${sizePx(10)};
    margin: 0;

    &:not(:first-of-type) {
      margin-top: -${sizePx(10)};
    }

    &[title]::before {
      content: attr(title);
      display: block;
      ${typography.Art}
      height: ${pxToRem(48)};
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ${StyledListItem} {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      color: ${colors.darkBlue100};

      > span,
      > a {
        line-height: 54px;
        display: block;
        flex-grow: 1;
      }

      &:not(:last-child) {
        border-bottom: 1px solid ${colors.grey50};
      }

      &:hover {
        color: ${colors.red100};
      }
    }
  }
`;

const sharedNavigationBtnStyling = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-color: transparent;
  color: ${colors.darkBlue50};
  position: absolute;
  width: 40px;
  height: 40px;
  ${focus.brand('inset')}

  &:hover,
  &:active,
  &:focus-visible {
    color: ${colors.darkBlue100};
    background: transparent;
    border-color: transparent;
  }
`;

export const StyledCloseButton = styled.button`
  ${sharedNavigationBtnStyling}
  font-size: ${pxToRem(25)};
  font-weight: bold;
  align-self: end;
`;

export const StyledGoBackButton = styled.button`
  ${sharedNavigationBtnStyling}
  padding: 0;

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top: 3px solid;
    border-left: 3px solid;
    border-color: ${colors.darkBlue50};
    transform: rotate(-45deg);
    position: absolute;
  }

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 0px;
    border-bottom: 3px solid;
    border-color: ${colors.darkBlue50};
  }

  &:hover,
  &:active,
  &:focus-visible {
    &::after,
    &::before {
      border-color: ${colors.darkBlue100};
    }
  }
`;

export const StyledModalBackdrop = styled.div<StyledModalBackdropProps>`
  display: ${(props) => (props.$show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.modalBackdrop};
  background: rgba(0, 0, 0, 0.3);
  cursor: default;

  @media only screen and ${breakpoints.device.l} {
    display: none;
  }
`;
