'use client';
import styled from 'styled-components';
import container from '../src/atomic-design/theme/constants/container';
import colors from '../src/atomic-design/theme/constants/colors';

export const StyledBody = styled.body`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: var(--sul-sans);

  * {
    box-sizing: border-box;
  }
`;

export const StyledContainer = styled.div`
  box-sizing: border-box;
  max-width: ${container.maxWidth};
  width: 100%;
  padding: ${container.padding};
  height: 100%;
  margin: 0 auto;
`;
