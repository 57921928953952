import styled, {css} from 'styled-components';
import {StyledIconButtonProps} from './typings';
import StyledIcon from '@/atoms/Icon/styles';
import colors from '@/theme/constants/colors';

export const StyledIconButton = styled.button<StyledIconButtonProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: ${(props) => props.$button?.background?.default ?? 'transparent'};
  width: ${(props) => (props.$button?.width ? `${props.$button.width}px` : 'fit-content')};
  height: ${(props) => (props.$button?.height ? `${props.$button.height}px` : 'fit-content')};
  border-radius: ${(props) => props.$button?.border?.radius ?? 0}px;
  border-color: ${(props) => props.$button?.border?.color?.default ?? 'transparent'};
  border-width: ${(props) => props.$button?.border?.width ?? 0}px;

  &:hover {
    ${(props) =>
      props.$button?.background?.hover && `background: ${props.$button.background.hover};`}
    ${(props) =>
      props.$button?.border?.color?.hover && `border-color: ${props.$button.border.color.hover};`}

    ${StyledIcon} {
      ${(props) => props.$icon?.color?.hover && `color: ${props.$icon.color.hover}`}
    }
  }

  &:active {
    ${(props) =>
      props.$button?.background?.active && `background: ${props.$button?.background?.active};`}
    ${(props) =>
      props.$button?.border?.color?.active &&
      `border-color: ${props.$button?.border?.color?.active};`}

      ${StyledIcon} {
      ${(props) => props.$icon?.color?.active && `color: ${props.$icon.color.active}`}
    }
  }

  ${(props) =>
    props.$active &&
    css`
      ${props.$button?.background?.active && `background: ${props.$button?.background?.active};`}
      ${props.$button?.border?.color?.active &&
      `border-color: ${props.$button?.border?.color?.active};`}

    ${StyledIcon} {
        ${props.$icon?.color?.active && `color: ${props.$icon.color.active}`}
      }
    `}

  ${(props) =>
    props.$disabled &&
    css`
      background: ${colors.grey25};
      border-color: ${colors.grey25};
      pointer-events: none;

      ${StyledIcon} {
        color: ${colors.grey100};
      }
    `}  

  &:focus {
    outline: none;
  }
`;
