import React, {ReactElement, useState} from 'react';
import Props from './typings';
import {
  StyledDropdownContainer,
  StyledDropdownHeader,
  StyledDropdownListContainer,
  StyledDropdownList,
  StyledListItem,
} from './styles';
import Icon from '@/atoms/Icon/Icon';
import colors from '@/theme/constants/colors';
import useOutsideClick from '@/hooks/use-outside-click/use-outside-click';
import constantsFactory from '@/utils/constants';

const {DATA_TEST_ID} = constantsFactory();

const ListDropdown = ({
  options,
  selected,
  title,
  type,
  dropdownPosition = 'left',
  rightPadding,
  tick = true,
  maxHeight,
  wide,
  callback,
}: Props): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleState = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  const dropdownRef = useOutsideClick(closeDropdown, isOpen);

  const onOptionClicked = (key) => () => {
    setIsOpen(false);
    callback(key);
  };

  const getTitle = (): string => {
    if (!title) return '';

    if (selected) {
      const selectedOption = options.find((option) => option.key === selected);
      return selectedOption ? selectedOption.label : title;
    } else {
      return title;
    }
  };

  return (
    <StyledDropdownContainer ref={dropdownRef} $wide={wide}>
      <StyledDropdownHeader
        $opened={isOpen}
        $type={type}
        $selected={!!selected}
        onClick={toggleState}
        $wide={wide}
      >
        {type === 'icon' ? (
          <Icon type="ellipsis-vertical" color={colors.darkBlue100} fontSize={18} />
        ) : (
          getTitle()
        )}
      </StyledDropdownHeader>

      {isOpen && (
        <StyledDropdownListContainer
          data-testid={DATA_TEST_ID.DROPDOWN}
          $position={dropdownPosition}
          $maxHeight={maxHeight}
        >
          <StyledDropdownList>
            {options.map((option) => (
              <StyledListItem
                $rightPadding={rightPadding}
                $tick={tick}
                $selected={selected === option.key}
                onClick={onOptionClicked(option.key)}
                key={option.key}
              >
                {option.label}
              </StyledListItem>
            ))}
          </StyledDropdownList>
        </StyledDropdownListContainer>
      )}
    </StyledDropdownContainer>
  );
};

export default ListDropdown;
