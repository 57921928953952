import styled, {css} from 'styled-components';
import {
  StyledButtonProps,
  StyledDropdownLinkProps,
  StyledSearchDropdownProps,
  StyledSearchProps,
} from './typings';
import StyledIcon from '../../atoms/Icon/styles';
import colors from '../../theme/constants/colors';
import pxToRem from '@/theme/helpers/pxToRem';
import {sizePx} from '@/theme/helpers/size';
import typography from '@/theme/typography';
import breakpoints from '@/theme/constants/breakpoints';
import scrollbar from '@/theme/elements/scrollbar';
import Link from 'next/link';
import zIndex from '@/theme/constants/zIndex';
import StyledImgFigure from '@/atoms/Image/styles';

const dropdownBottomMargin = 20;

const theme = {
  header: {
    default: {
      height: 51,
      padding: 13,
      background: colors.white, // TODO: when header will be white, change background to colors.grey25
      borderColor: colors.grey25,
      iconColor: colors.red100,
      iconSize: 19,
    },
    hover: {
      background: colors.grey25,
      borderColor: colors.grey100,
      iconColor: colors.darkBlue100,
    },
    active: {
      background: colors.white,
      borderColor: colors.darkBlue100,
      iconColor: colors.darkBlue100,
    },
  },
  content: {
    default: {
      height: 44,
      padding: 11,
      background: colors.white,
      borderColor: colors.grey100,
      iconColor: colors.grey100,
      iconSize: 18,
    },
    hover: {
      background: colors.white,
      borderColor: colors.darkBlue100,
      iconColor: colors.darkBlue100,
    },
    active: {
      background: colors.white,
      borderColor: colors.darkBlue100,
      iconColor: colors.darkBlue100,
    },
  },
};

const searchWidth = (location, maxWidth, collapsed) => {
  const width = maxWidth ?? '100%';
  const collapsedWidth = `${theme[location]?.default.height}px`;

  return collapsed ? collapsedWidth : width;
};

const sharedBtnStyle = css`
  position: absolute;
  top: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const StyledSearchButton = styled.button<StyledButtonProps>`
  ${sharedBtnStyle}
  left: 0;
  width: ${(props) => theme[props.$location]?.default.height}px;
  height: 100%;
  padding: 0;

  ${StyledIcon} {
    color: ${(props) => theme[props.$location]?.default.iconColor};
    font-size: ${(props) => pxToRem(theme[props.$location]?.default.iconSize)};
  }
`;

export const StyledCancelButton = styled.button<StyledButtonProps>`
  ${sharedBtnStyle}
  justify-content: flex-start;
  padding: 0 0 0 2px;
  right: 0;
  width: 30px;
  height: ${(props) => theme[props.$location]?.default.height}px;

  ${StyledIcon} {
    color: ${colors.grey100};
    font-size: ${pxToRem(13)};
  }
`;

export const StyledSearch = styled.div<StyledSearchProps>`
  position: relative;
  display: inline-flex;
  padding: 0;
  height: ${(props) => theme[props.$location]?.default.height}px;
  border-radius: 26px;
  background: ${(props) =>
    props.$collapsed ? 'transparent' : theme[props.$location]?.default.background};
  border: ${(props) =>
    props.$collapsed
      ? '1px solid transparent !important'
      : `1px solid ${theme[props.$location]?.default.borderColor}`};
  width: ${(props) => searchWidth(props.$location, props.$maxWidth, props.$collapsed)};
  ${(props) =>
    props.$collapsible &&
    css`
      transition: background 0.3s ease-out, border 0.3s ease-out, width 0.3s ease-out;
    `}

  &:hover:not(:focus-within) {
    border: 1px solid ${(props) => theme[props.$location]?.hover.borderColor};

    ${StyledSearchButton} {
      ${StyledIcon} {
        color: ${(props) => theme[props.$location]?.hover.iconColor};
      }
    }

    ${StyledCancelButton} {
      ${StyledIcon} {
        color: ${colors.darkBlue100};
      }
    }
  }

  &:focus-within {
    background: ${(props) => theme[props.$location]?.active.background};
    border: 1px solid ${(props) => theme[props.$location]?.active.borderColor};

    ${StyledSearchButton} {
      ${StyledIcon} {
        color: ${(props) => theme[props.$location]?.active.iconColor};
      }
    }

    ${StyledCancelButton} {
      ${StyledIcon} {
        color: ${colors.darkBlue100};
      }
    }
  }

  input {
    flex-grow: 1;
    border: none;
    background: transparent;
    margin-left: 51px;
    margin-right: 30px;
    min-width: 0;
    padding: 0;
    line-height: ${(props) => theme[props.$location]?.default.height - 2}px;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
`;

export const StyledSearchContainer = styled.div`
  width: fit-content;
  position: relative;
`;

const dropdownLinkHoverState = css`
  color: ${colors.darkBlue100};
  font-weight: bold;
  box-shadow: none !important;
  background: ${colors.grey25};

  ${StyledIcon} {
    color: ${colors.red100};
  }

  ${StyledImgFigure} {
    border-color: ${colors.red100};
  }
`;

export const StyledDropdownLink = styled(Link)<StyledDropdownLinkProps>`
  height: 49px;
  display: flex;
  align-items: center;
  padding: 0 ${sizePx(10)};

  p {
    margin: 0;
    padding: 0;
    max-height: 43px;
    overflow: hidden;
  }

  ${StyledIcon} {
    width: 40px;
    height: 40px;
    margin-right: ${sizePx(5)};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${StyledImgFigure} {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid transparent;
    margin-right: ${sizePx(5)};

    img {
      padding: ${sizePx(1)};
      border-radius: 50%;
    }
  }

  &:hover {
    color: inherit;
  }

  ${(props) =>
    props.$hover &&
    css`
      ${dropdownLinkHoverState}
    `}
`;

export const StyledDropdownA = styled(StyledDropdownLink).attrs({as: 'a'})``;

export const StyledSearchDropdown = styled.div<StyledSearchDropdownProps>`
  background: ${colors.white};
  padding: 0;
  box-shadow: 0 2px 10px 4px rgba(0, 0, 0, 0.11);
  position: absolute;
  top: calc(100% + 10px);
  ${typography.Body2}
  color: ${colors.darkBlue100};
  max-height: ${(props) => (props.$maxHeight ? `calc(100vh - ${props.$maxHeight}px)` : 'auto')};
  overflow-y: auto;
  overflow-x: hidden;
  width: 100vw;
  left: 0;
  margin-left: calc(-50vw + 50%);
  z-index: ${zIndex.dropdown};

  ${scrollbar}

  @media only screen and ${breakpoints.device.s} {
    width: 100%;
    left: auto;
    margin-left: unset;
    max-height: ${(props) =>
      props.$maxHeight ? `calc(100vh - ${props.$maxHeight + dropdownBottomMargin}px)` : 'auto'};
  }

  > figure {
    margin: 0;
    padding: 0;

    > figcaption {
      ${typography.Subtitle2}
      line-height: 49px;
      padding: 0 ${sizePx(10)};
      cursor: default;
      text-transform: capitalize;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    &:not(:last-child) {
      a {
        box-shadow: inset 20px 0 0 0px ${colors.white}, inset -20px 0 0 0px ${colors.white},
          inset 0 -1px 0 ${colors.grey25};
      }
    }
  }
`;
