import React, {ReactElement} from 'react';
import Props from './typings';
import StyledIcon from './styles';

const Icon = ({type, fontSize, color, label, className}: Props): ReactElement => {
  return (
    <StyledIcon
      className={`icon-${type}${className ? ` ${className}` : ''}`}
      $type={type}
      $fontSize={fontSize}
      $color={color}
      role="img"
      aria-label={label}
    />
  );
};

export default Icon;
