/* eslint-disable camelcase */
// This is a helper function mainly for working with gallery filters
const paramsHelper = (parameters: object): ParamsHelperObjectProps => ({
  result: parameters,

  // Gets rid of params that don't hold value, adds any additional params
  build(additionalParams = {}) {
    if (!this.result) return this;

    const params = {};

    // eslint-disable-next-line require-jsdoc
    const insideRecursion = (object) => {
      for (const [key, value] of Object.entries(object)) {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
          insideRecursion(value);
        } else if (Array.isArray(value) && value.length > 0) {
          params[key] = value;
        } else if (value && !Array.isArray(value)) {
          params[key] = value;
        }
      }
    };

    const original = {...this.result, ...additionalParams};

    insideRecursion(original);

    this.result = {...params};

    return this;
  },

  unwrapCategories() {
    if (!this.result) return this;

    const categoriesToExclude = [
      'painting',
      'printmaking',
      'sculpture',
      'photography',
      'drawing',
      'digital-art',
    ];

    const shallowCopy = {...this.result};
    let categories: string[] = [];

    const topCategories = Object.keys(shallowCopy).filter((key) => {
      if (categoriesToExclude.includes(key)) {
        categories.push(...shallowCopy[key]);
        delete shallowCopy[key];
        return true;
      } else {
        return false;
      }
    });

    if (Object.keys(shallowCopy).includes('product_category')) {
      categories.push(...shallowCopy.product_category);
      delete shallowCopy.product_category;
    }

    categories = categories.filter((category) => !topCategories.includes(category));

    if (categories.length) shallowCopy.category = categories;

    this.result = shallowCopy;

    return this;
  },

  filter(paramsToExclude) {
    if (!this.result) return this;

    const shallowCopy = {...this.result};

    Object.keys(shallowCopy).forEach((key) => {
      if (paramsToExclude.includes(key)) {
        delete shallowCopy[key];
      }
    });

    this.result = shallowCopy;

    return this;
  },

  // Sorts params object based on passed in config
  sort(config) {
    if (!this.result) return this;

    const shallowCopy = {...this.result};
    const sortedObject = {};
    const sortedKeys = Object.keys(config).sort((a, b) => Number(a) - Number(b));

    sortedKeys.forEach((key) => {
      if (shallowCopy.hasOwnProperty(config[key])) {
        sortedObject[config[key]] = shallowCopy[config[key]];
        delete shallowCopy[config[key]];
      }
    });

    Object.keys(shallowCopy).forEach((key) => {
      sortedObject[key] = shallowCopy[key];
    });

    this.result = sortedObject;

    return this;
  },

  // Returns string for API request
  objectToQueryString() {
    if (!this.result) return '';

    const params: string[] = [];

    const insideRecursion = (data) => {
      for (const [key, value] of Object.entries(data)) {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
          insideRecursion(value);
        } else if (Array.isArray(value) && value.length > 0) {
          for (let i = 0; i < value.length; i++) {
            params.push(`${key}=${value[i]}`);
          }
        } else if (value && !Array.isArray(value)) {
          params.push(`${key}=${value}`);
        }
      }
    };

    insideRecursion(this.result);

    return params.length > 0 ? '?' + params.join('&') : '';
  },
});

export default paramsHelper;

interface ConfigObjectProps {
  [key: number]: string;
}
interface ParamsHelperObjectProps {
  result: object;
  build: (additionalParams?: object) => ParamsHelperObjectProps;
  unwrapCategories: () => ParamsHelperObjectProps;
  filter: (paramsToExclude: string[]) => ParamsHelperObjectProps;
  sort: (config: ConfigObjectProps) => ParamsHelperObjectProps;
  objectToQueryString: () => string;
}
