import {nextImageThumborLoader} from './thumbor-url-builder';

const DEFAULT_QUALITY = 100;
const FALLBACK_WIDTH = 500;
const PRODUCT_CARD_SIZES = '(min-width: 1200px) 25vw, (min-width: 500px) 30vw, 50vw';
const PRODUCT_GALLERY_SIZES = '(min-width: 1000px) 60vw, 90vw';

const ImageLoader = (path: string, width: number, quality: number, height: number = 0) => {
  const customLoaders = {
    thumborLoader: nextImageThumborLoader({
      src: path,
      width: width,
      height: height,
      quality: quality,
    }),
  };

  return {
    get: (name) => {
      if (name === 'default') {
        return path;
      } else {
        if (customLoaders.hasOwnProperty(`${name}Loader`)) {
          return customLoaders[`${name}Loader`];
        } else {
          return path;
        }
      }
    },
  };
};

export default ImageLoader;

const getImageUrl = (path, width, height = 0, quality = 100) => {
  return nextImageThumborLoader({src: path, width: width, height: height, quality: quality});
};

const productGalleryImageSrcSetDef = (path) => {
  if (!path) return undefined;

  return (
    `${getImageUrl(path, 560)} 560w, ` +
    `${getImageUrl(path, 780)} 780w, ` +
    `${getImageUrl(path, 900)} 900w, ` +
    `${getImageUrl(path, 1120)} 1120w, `
  );
};

const productCardImageSrcSetDef = (path) => {
  if (!path) return undefined;

  return (
    `${getImageUrl(path, 185)} 185w, ` +
    `${getImageUrl(path, 250)} 250w, ` +
    `${getImageUrl(path, 320)} 320w, ` +
    `${getImageUrl(path, 500)} 500w`
  );
};

export {
  getImageUrl,
  productGalleryImageSrcSetDef,
  productCardImageSrcSetDef,
  DEFAULT_QUALITY,
  FALLBACK_WIDTH,
  PRODUCT_CARD_SIZES,
  PRODUCT_GALLERY_SIZES,
};
