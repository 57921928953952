import {StyledCloseButton, StyledModal, StyledModalBackdrop, StyledTopMenu} from './styles';
import Props from './typings';

const Modal = ({onClose, backdropColor, closeIconColor, children}: Props) => {
  return (
    <>
      <StyledModalBackdrop onClick={onClose} $background={backdropColor}></StyledModalBackdrop>
      <StyledModal role="dialog">
        {children}

        <StyledTopMenu>
          <StyledCloseButton
            onClick={onClose}
            $color={closeIconColor?.default}
            $hoverColor={closeIconColor?.hover}
            aria-label="Close dialog"
          />
        </StyledTopMenu>
      </StyledModal>
    </>
  );
};

export default Modal;
