import styled, {css} from 'styled-components';
import {StyledCtaButtonProps} from './typings';
import colors from '../../../theme/constants/colors';
import button from '../../../theme/constants/button';
import pxToRem from '../../../theme/helpers/pxToRem';
import focus from '@/theme/focus';

const theme = {
  primary: {
    default: {
      border: colors.red100,
      background: colors.red100,
      color: colors.white,
    },
    hover: {
      border: colors.red200,
      background: colors.red200,
      color: colors.white,
    },
    active: {
      border: colors.red75,
      background: colors.red75,
      color: colors.white,
    },
  },
  secondary: {
    default: {
      border: colors.grey100,
      background: colors.grey100,
      color: colors.darkBlue100,
    },
    hover: {
      border: colors.grey200,
      background: colors.grey200,
      color: colors.darkBlue100,
    },
    active: {
      border: colors.grey75,
      background: colors.grey75,
      color: colors.darkBlue100,
    },
  },
  tertiary: {
    default: {
      border: colors.darkBlue100,
      background: colors.darkBlue100,
      color: colors.white,
    },
    hover: {
      border: colors.darkBlue200,
      background: colors.darkBlue200,
      color: colors.white,
    },
    active: {
      border: colors.darkBlue75,
      background: colors.darkBlue75,
      color: colors.white,
    },
  },
};

const StyledCtaButton = styled.button<StyledCtaButtonProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  font-family: 'sul-sans', helvetica, sans-serif;
  height: ${button.height}px;
  padding: ${pxToRem(button.paddingY)} ${pxToRem(button.paddingX)};
  font-size: ${pxToRem(button.fontSize)};
  border-radius: 4px;
  border: 1px solid ${(props) => theme[props.$style].default.border};
  color: ${(props) => theme[props.$style].default.color};
  background: ${(props) => theme[props.$style].default.background};
  ${(props) =>
    props.$maxWidth &&
    css`
      width: 100%;
      max-width: ${props.$maxWidth};
    `}

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border-color: ${(props) => theme[props.$style].hover.border};
      color: ${(props) => theme[props.$style].hover.color};
      background: ${(props) => theme[props.$style].hover.background};
    }
  }

  &:active {
    border-color: ${(props) => theme[props.$style].active.border};
    color: ${(props) => theme[props.$style].active.color};
    background: ${(props) => theme[props.$style].active.background};
  }

  ${focus.brand(`inset 0px 0px 0px 1px ${colors.white}`)}

  &[aria-disabled='true'] {
    color: ${colors.grey100};
    background: ${colors.grey25};
    border-color: ${colors.grey25};
    pointer-events: none;
  }
`;

export default StyledCtaButton;
