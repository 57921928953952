import axios from 'axios';
import constantsFactory from '../../src/utils/constants';

// Action Types
import {
  SET_ACTIVE_FILTERS,
  SET_CATEGORY_FILTER,
  SET_COLOR_FILTER,
  SET_FREE_SHIPPING_FILTER,
  SET_FRAMED_FILTER,
  SET_KEYWORD_FILTER,
  SET_ON_SALE_FILTER,
  SET_PRICE_FILTER,
  SET_PRINTS_FILTER,
  SET_SIZE_FILTER,
  SET_SHAPE_FILTER,
  SET_SHIPS_TO_FILTER,
  SET_SHIPS_FROM_FILTER,
  SET_SORT_FILTER,
  SET_STYLE_FILTER,
  SET_URL_FILTERS,
  RESET_CATEGORY_FILTER,
  RESET_COLOR_FILTER,
  RESET_FRAMED_FILTER,
  RESET_KEYWORD_FILTER,
  RESET_PRICE_FILTER,
  RESET_PRINTS_FILTER,
  RESET_SIZE_FILTER,
  RESET_SHAPE_FILTER,
  RESET_SHIPS_TO_FILTER,
  RESET_SHIPS_FROM_FILTER,
  RESET_SORT_FILTER,
  RESET_STYLE_FILTER,
  RESET_ALL_FILTERS,
  CHANGE_SHIPS_TO_COUNTRY,
  LOAD_PRICE_BARS_REQUEST,
  LOAD_PRICE_BARS_SUCCESS,
  LOAD_PRICE_BARS_FAILURE,
  STATUS_LOADING,
  SET_SUBJECT_FILTER,
  RESET_SUBJECT_FILTER,
  SET_PAGINATION_SETTINGS,
} from '../actionTypes';
import paramsHelper from '../../src/utils/paramsHelper';

const {API} = constantsFactory();
const PRICE_DISTRIBUTION_URL = `${process.env.HOST}${API.PRICE_DISTRIBUTION}`;

// global actions
export const loadPriceBars = (
  params,
  onSuccessCallbacks = [],
  onErrorCallbacks = [],
  cleanupCallbacks = [],
  signal,
) => {
  const query = paramsHelper(params).objectToQueryString();

  return {
    // Types of actions to emit before and after
    types: [LOAD_PRICE_BARS_REQUEST, LOAD_PRICE_BARS_SUCCESS, LOAD_PRICE_BARS_FAILURE],
    // Check if we already have a cached result (optional):
    shouldCallAPI: (state) => state.filterSettings.priceBars.status !== STATUS_LOADING,
    // Perform the fetching:
    callAPI: () =>
      axios.get(`${PRICE_DISTRIBUTION_URL}${query}`, {
        headers: {Accept: 'application/json', 'x-api-key': process.env.API_AUTH_X_API_KEY},
        signal: signal,
      }),
    // Arguments to inject in begin/end actions
    payload: {},
    // Functions to run on success
    onSuccess: onSuccessCallbacks,
    // Functions to run to on cleanup
    onCleanup: cleanupCallbacks,
    // Functions to run on Error
    onError: onErrorCallbacks,
  };
};

export const setCategory = (categoryData) => ({
  type: SET_CATEGORY_FILTER,
  payload: categoryData,
});

export const setColor = (colorData) => ({
  type: SET_COLOR_FILTER,
  payload: colorData,
});

export const setPrints = (prints) => ({
  type: SET_PRINTS_FILTER,
  payload: prints,
});

export const setFramed = (framed) => ({
  type: SET_FRAMED_FILTER,
  payload: framed,
});

export const setKeyword = (keyword) => ({
  type: SET_KEYWORD_FILTER,
  payload: keyword,
});

export const setOnSale = (inSale) => ({
  type: SET_ON_SALE_FILTER,
  payload: inSale,
});

export const setFreeShipping = (freeShipping, country) => ({
  type: SET_FREE_SHIPPING_FILTER,
  payload: {freeShipping, country},
});

export const setShipTo = (country) => ({
  type: SET_SHIPS_TO_FILTER,
  payload: {country},
});

export const setShipFrom = (country) => ({
  type: SET_SHIPS_FROM_FILTER,
  payload: {country},
});

export const changeShipsToCountry = (country) => ({
  type: CHANGE_SHIPS_TO_COUNTRY,
  payload: {country},
});

export const setPrice = (priceData) => ({
  type: SET_PRICE_FILTER,
  payload: priceData,
});

export const setSize = (sizeData) => ({
  type: SET_SIZE_FILTER,
  payload: sizeData,
});

export const setShape = (shapeData) => ({
  type: SET_SHAPE_FILTER,
  payload: shapeData,
});

export const setSort = (sortData) => ({
  type: SET_SORT_FILTER,
  payload: sortData,
});

export const setStyle = (styleData) => ({
  type: SET_STYLE_FILTER,
  payload: styleData,
});

export const setSubject = (subjectData) => ({
  type: SET_SUBJECT_FILTER,
  payload: subjectData,
});

export const setUrlFilters = (urlData) => ({
  type: SET_URL_FILTERS,
  payload: urlData,
});

export const setPaginationSettings = (perCall, paginate) => ({
  type: SET_PAGINATION_SETTINGS,
  payload: {paginate: paginate, limit: perCall},
});

export const resetCategory = () => ({type: RESET_CATEGORY_FILTER});

export const resetColor = () => ({type: RESET_COLOR_FILTER});

export const resetFramed = () => ({type: RESET_FRAMED_FILTER});

export const resetKeyword = () => ({type: RESET_KEYWORD_FILTER});

export const resetPrice = () => ({type: RESET_PRICE_FILTER});

export const resetSize = () => ({type: RESET_SIZE_FILTER});

export const resetPrints = () => ({type: RESET_PRINTS_FILTER});

export const resetShape = () => ({type: RESET_SHAPE_FILTER});

export const resetShipTo = () => ({type: RESET_SHIPS_TO_FILTER});

export const resetShipFrom = () => ({type: RESET_SHIPS_FROM_FILTER});

export const resetSort = () => ({type: RESET_SORT_FILTER});

export const resetStyle = () => ({type: RESET_STYLE_FILTER});

export const resetSubject = () => ({type: RESET_SUBJECT_FILTER});

export const resetAllFilters = () => ({type: RESET_ALL_FILTERS});

export const setActiveFilters = () => ({type: SET_ACTIVE_FILTERS});
