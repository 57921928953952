import styled from 'styled-components';
import colors from '../../theme/constants/colors';
import zIndex from '../../theme/constants/zIndex';
import {StyledCloseButtonProps, StyledModalBackdropProps} from './typings';
import breakpoints from '../../theme/constants/breakpoints';

export const StyledModal = styled.div`
  background: ${colors.white};
  border-radius: 0;
  z-index: ${zIndex.modal};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  min-width: 100px;
  min-height: 140px;
  max-height: 100vh;
  max-width: 100vw;
  display: flex;
  cursor: default;

  > * {
    flex-grow: 1;
  }

  @media only screen and ${breakpoints.device.s} {
    min-width: 400px;
    max-height: calc(100vh - 20px);
    max-width: calc(100vw - 20px);
    width: fit-content;
    height: fit-content;
    border-radius: 15px;
  }
`;

export const StyledTopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
`;

const crossStyling = (color) => `
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 14px;
    height: 3px;
    border-radius: 1px;
    background: ${color ?? colors.darkBlue50};
    transition: background 0.2s;
`;

export const StyledCloseButton = styled.button<StyledCloseButtonProps>`
  width: 25px;
  height: 25px;
  margin: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;

  &::before {
    ${(props) => crossStyling(props.$color)}
    transform: rotate(-45deg);
  }

  &::after {
    ${(props) => crossStyling(props.$color)}
    transform: rotate(45deg);
  }

  &:hover {
    &::before,
    &::after {
      background: ${(props) => props.$hoverColor ?? colors.red100};
    }
  }

  &:focus {
    outline: none;
  }
`;

export const StyledModalBackdrop = styled.div<StyledModalBackdropProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.modalBackdrop};
  background: ${(props) => (props.$background ? props.$background : 'rgba(0,0,0,0.3)')};
  cursor: default;
`;
