'use client';
import styled from 'styled-components';
import {StyledNotificationProps} from './typings';
import colors from '../../../theme/constants/colors';
import pxToRem from '../../../theme/helpers/pxToRem';

const notificationSize = 15;

export const StyledNotificationWrapper = styled.span`
  position: relative;
  display: inline-flex;
`;

export const StyledNotification = styled.span<StyledNotificationProps>`
  width: ${notificationSize}px;
  height: ${notificationSize}px;
  background: ${colors.red100};
  border-radius: 50%;
  border: 1px solid ${(props) => props.$borderColor ?? colors.white};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white} !important;
  font-size: ${pxToRem(8)};
  font-weight: bold;
  ${(props) =>
    props.$position === 'top'
      ? `top: -${pxToRem(10)};
      position: absolute;
      right: -${pxToRem(7)};`
      : `
    margin: auto ${pxToRem(5)} auto;`}
`;
