const categoryTypes = {
  painting: [
    'painting-acrylic',
    'painting-gouache',
    'painting-oil',
    'painting-watercolour',
    'painting-other',
    'painting-spray-paint',
    'painting-mixed-media',
  ],
  printmaking: [
    'printmaking-monoprint',
    'printmaking-screenprints',
    'printmaking-lithographs',
    'printmaking-etchings-engravings',
    'printmaking-linocuts',
    'printmaking-woodcuts',
    'printmaking-collagraphs',
    'printmaking-other',
  ],
  sculpture: [
    'sculpture-stone',
    'sculpture-clay',
    'sculpture-wood',
    'sculpture-mixed-media',
    'sculpture-relief',
    'sculpture-bronze',
    'sculpture-other',
  ],
  photography: ['photography-colour', 'photography-black-and-white', 'photography-manipulated'],
  drawing: ['drawing-charcoal', 'drawing-ink', 'drawing-pastel', 'drawing-pencil', 'drawing-other'],
  digital_art: ['digital-art-giclee', 'digital-art-c-type', 'digital-art-other'],
};

const replaceParams = {
  digital_art: 'digital-art',
};

const baseCheck = {
  number: {
    convert: (value) => Number(value),
    isUnique: true,
    isValid: (value) => {
      return Number.isInteger(Number(value)) && Number(value) > 0;
    },
  },
  trueOnly: {
    convert: (value) => value === 'true',
    isUnique: true,
    isValid: (value) => {
      return value === 'true';
    },
  },
};

const acceptedUrlParams = {
  colours: {
    convert: (value) => value,
    isUnique: false,
    isValid: (value) => {
      return value.length <= 6;
    },
  },
  sort: {
    convert: (value) => value,
    isUnique: true,
    isValid: (value) => {
      const acceptedStrings = ['best_match', 'price_asc', 'price_desc', 'newest'] as const;
      return acceptedStrings.includes(value);
    },
  },
  product_category: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      const acceptedStrings = [
        'painting',
        'printmaking',
        'sculpture',
        'photography',
        'drawing',
        'digital-art',
        'collage',
      ];
      return acceptedStrings.includes(value);
    },
  },
  subject: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      const acceptedStrings = [
        'abstract-conceptual',
        'animals-birds',
        'architecture-cityscapes',
        'flowers-plants',
        'landscapes-sea-sky',
        'nudes-erotic',
        'people-portraits',
        'still-life',
        'transportation-maps',
      ];
      return acceptedStrings.includes(value);
    },
  },
  style: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      const acceptedStrings = [
        'abstract',
        'abstract-organic',
        'abstract-geometric',
        'collage',
        'photorealistic',
        'impressionistic',
        'expressive-and-gestural',
        'graphic-illustrative-and-typographic',
        'graphic-illustrative-and-typographic-cartoon',
        'graphic-illustrative-and-typographic-graphic',
        'graphic-illustrative-and-typographic-illustrative',
        'graphic-illustrative-and-typographic-typographic',
        'surrealistic',
        'urban-and-pop',
        'naive',
        'unspecified',
      ];
      return acceptedStrings.includes(value);
    },
  },
  price_min: {
    ...baseCheck.number,
  },
  price_max: {
    ...baseCheck.number,
  },
  in_sale: {
    convert: (value) => (value === 'on' ? 'on' : Number(value)),
    isUnique: true,
    isValid: (value) => {
      const acceptedStrings = ['on', '20', '30', '50'] as const;
      return acceptedStrings.includes(value);
    },
  },
  free_shipping: {
    ...baseCheck.trueOnly,
  },
  framed_or_ready_to_hang: {
    ...baseCheck.trueOnly,
  },
  size_: {
    convert: (value) => value,
    isUnique: true,
    isValid: (value) => {
      const acceptedStrings = ['small', 'medium', 'large', 'xlarge'] as const;
      return acceptedStrings.includes(value);
    },
  },
  has_fine_art_prints: {
    ...baseCheck.trueOnly,
  },
  height_min: {
    ...baseCheck.number,
  },
  height_max: {
    ...baseCheck.number,
  },
  width_min: {
    ...baseCheck.number,
  },
  width_max: {
    ...baseCheck.number,
  },
  page: {
    ...baseCheck.number,
  },
  q: {
    convert: (value) => value,
    isUnique: true,
    isValid: (value) => {
      return value.length > 0;
    },
  },
  product_boost: {
    convert: (value) => value,
    isUnique: true,
    isValid: (value) => {
      return value.length > 0;
    },
  },
  multiple_product_boost: {
    convert: (value) => {
      const validInput = value
        .split('-')
        .filter((id) => Number.isInteger(Number(id)) && Number(id) > 0);
      return validInput.join('-');
    },
    isUnique: true,
    isValid: (value) => {
      const ids = value.split('-');
      let wrongValues = 0;
      for (const id of ids) {
        if (!Number.isInteger(Number(id)) || Number(id) < 1) {
          wrongValues++;
        }
      }
      return wrongValues === ids.length ? false : true;
    },
  },
  painting: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      return categoryTypes.painting.includes(value);
    },
  },
  printmaking: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      return categoryTypes.printmaking.includes(value);
    },
  },
  sculpture: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      return categoryTypes.sculpture.includes(value);
    },
  },
  photography: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      return categoryTypes.photography.includes(value);
    },
  },
  drawing: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      return categoryTypes.drawing.includes(value);
    },
  },
  digital_art: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      return categoryTypes.digital_art.includes(value);
    },
  },
  shape: {
    convert: (value) => [value],
    isUnique: true,
    isValid: (value) => {
      const acceptedShapes = ['portrait', 'landscape', 'square'];
      return acceptedShapes.includes(value);
    },
  },
  ships_to: {
    convert: (value) => value,
    isUnique: true,
    isValid: (value) => {
      return value.length > 0;
    },
  },
  ships_from: {
    convert: (value) => value,
    isUnique: true,
    isValid: (value) => {
      return value.length > 0;
    },
  },
  verified_advertising_safe: {
    ...baseCheck.trueOnly,
  },
} as const;

const hiddenParams = ['paginate', 'limit'];

const validateParams = (rawParams) => {
  const validParams = {};

  rawParams.forEach((param) => {
    let [key, value] = param.split(/-(.+)/).map((el) => el.replace(/\s+/g, ' ').trim());

    const validParam = () =>
      value && acceptedUrlParams.hasOwnProperty(key) && acceptedUrlParams[key].isValid(value);
    const inObject = () => validParams.hasOwnProperty(key);
    const attachable = () => !acceptedUrlParams[key].isUnique;
    const targetIsArray = () =>
      Array.isArray(validParams[key]) && !validParams[key].includes(value);
    const targetIsString = () => typeof validParams[key] === 'string' && value !== validParams[key];

    if (validParam()) {
      const validatedValue = acceptedUrlParams[key].convert(value);
      if (Object.keys(replaceParams).includes(key)) key = replaceParams[key];

      if (inObject()) {
        if (attachable()) {
          if (targetIsString()) {
            validParams[key] = [validParams[key], validatedValue];
          } else if (targetIsArray()) {
            validParams[key] = [...validParams[key], validatedValue];
          }
        }
      } else {
        validParams[key] = validatedValue;
      }
    }
  });

  return validParams;
};

const prefix = (paramsObject): string[] => {
  const filteredArray = hideSomeParams(paramsObject);
  const prefixedArray = filteredArray.flatMap(([key, value]) =>
    (Array.isArray(value) ? value : [value]).map((value) => {
      key = Object.values(replaceParams).includes(key)
        ? Object.keys(replaceParams).find((k) => replaceParams[k] === key) ?? key
        : key;
      return `${key}-${value}`;
    }),
  );
  return prefixedArray;
};

const hideSomeParams = (paramsObject: object) => {
  const filteredArray = Object.entries(paramsObject).filter(([key, value]) => {
    if (
      hiddenParams.includes(key) ||
      (key === 'page' && value == '1') ||
      (key === 'sort' && value == 'best_match')
    ) {
      return false;
    }
    return true;
  });
  return filteredArray;
};

export {prefix, validateParams};
