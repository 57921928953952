import {useEffect, useRef} from 'react';

const useOutsideClick = (callback, listen) => {
  const ref: any = useRef();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    if (listen && ref.current) {
      document.addEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [listen]);

  return ref;
};

export default useOutsideClick;
